<template>
  <div
    :class="[
      'font-weight-bold white--text text-center px-2 text-caption rounded',
      verificationStatus.class,
      paddingY]"
  >
    {{ verificationStatus.text }}
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'VerificationStatusChip',
    props: {
      paddingY: {
        type: String,
        default: 'py-1'
      }
    },
    computed: {
      ...mapState('personal', ['fetchIsLoading', 'verificationStatuses']),
      verificationStatus() {
        if (this.fetchIsLoading) {
          return { status: '', text: '', class: '' }
        }
        const statuses = [
          {
            status: 'verified',
            text: this.$t('main.verification_statuses.verified'),
            class: 'primary'
          },
          {
            status: 'pending',
            text: this.$t('main.verification_statuses.pending'),
            class: 'warning'
          },
          {
            status: 'rejected',
            text: this.$t('main.verification_statuses.rejected'),
            class: 'error'
          },
          {
            status: 'not verified',
            text: this.$t('main.verification_statuses.not_verified'),
            class: 'secondary-darken'
          }
        ]
        const cardStatus = this.verificationStatuses
          ? this.verificationStatuses.card
          : 'not verified'

        const currentState = statuses.find((item) => item.status === cardStatus)

        this.$emit('verified-status-handler', currentState.status === 'verified')

        return currentState
      }
    }
  }
</script>
