<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1080 650"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1080 0H0V650H1080V0Z" fill="white" />
    <g opacity="0.75">
      <g opacity="0.75">
        <path
          opacity="0.75"
          d="M618.4 27.3996L127 523.2C126.6 522.6 126.1 522 125.7 521.5L615.7 27.0996C616.6 27.1996 617.5 27.2996 618.4 27.3996Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.75">
        <path
          opacity="0.75"
          d="M463.5 22.7998L77.7 412C77.5 411.2 77.3 410.4 77.2 409.6L460.4 22.9998C461.4 22.8998 462.5 22.7998 463.5 22.7998Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.75">
        <path
          opacity="0.75"
          d="M160.7 193.3L82.2 272.5C82.7 271 83.2 269.5 83.8 268L157.2 193.9C158.3 193.7 159.5 193.5 160.7 193.3Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.75">
        <path
          opacity="0.75"
          d="M844.5 107.5L352.1 604.3H349.2L842.8 106.2L844.5 107.5Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.75">
        <path
          opacity="0.75"
          d="M745 56.0002L209.9 595.8C209.3 595.5 208.7 595.1 208 594.7L742.7 55.2002C743.4 55.5002 744.2 55.7002 745 56.0002Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.75">
        <path
          opacity="0.75"
          d="M903.5 182.9L485.4 604.4H482.5L902.6 180.7C902.9 181.4 903.2 182.1 903.5 182.9Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.75">
        <path
          opacity="0.75"
          d="M947.3 348.6L693.5 604.6H690.6L946.3 346.7C946.6 347.3 947 348 947.3 348.6Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.75">
        <path
          opacity="0.75"
          d="M1000.8 445.3L842.7 604.8H839.8L1000.2 443C1000.4 443.7 1000.6 444.5 1000.8 445.3Z"
          fill="#D4D6DB"
        />
      </g>
    </g>
    <path
      d="M369.3 77.3994H649.5C653.2 77.3994 656.1 80.3994 656.1 83.9994V498.399C656.1 502.099 653.2 504.999 649.5 504.999H369.3C365.6 504.999 362.7 501.999 362.7 498.399V83.8994C362.7 80.2994 365.6 77.3994 369.3 77.3994Z"
      fill="#D4D6DB"
    />
    <path
      d="M383.4 66.8994H663.6C667.3 66.8994 670.2 69.8994 670.2 73.4994V487.999C670.2 491.699 667.3 494.599 663.6 494.599H383.4C379.7 494.599 376.8 491.599 376.8 487.999V73.5994C376.8 69.9994 379.8 66.8994 383.4 66.8994Z"
      fill="white"
    />
    <path
      d="M663.6 495.399H383.4C379.3 495.399 376.1 491.999 376.1 487.999V73.5992C376.1 69.4992 379.4 66.1992 383.4 66.1992H663.6C667.7 66.1992 670.9 69.5992 670.9 73.5992V487.999C670.9 492.099 667.7 495.399 663.6 495.399ZM383.4 67.7992C380.3 67.7992 377.6 70.3992 377.6 73.5992V487.999C377.6 491.199 380.2 493.799 383.4 493.799H663.6C666.7 493.799 669.4 491.199 669.4 487.999V73.5992C669.4 70.3992 666.8 67.7992 663.6 67.7992H383.4Z"
      fill="black"
    />
    <path
      d="M491.5 186.099H415C410.6 186.099 406.9 182.499 406.9 177.999V100.899C406.9 96.4988 410.5 92.7988 415 92.7988H491.5C495.9 92.7988 499.6 96.3988 499.6 100.899V178.099C499.6 182.499 496 186.099 491.5 186.099Z"
      fill="currentColor"
    />
    <path
      d="M529.3 462.199H415.1C410.7 462.199 407 458.599 407 454.099V436.899C407 432.499 410.6 428.799 415.1 428.799H529.2C533.6 428.799 537.3 432.399 537.3 436.899V454.099C537.3 458.599 533.7 462.199 529.3 462.199Z"
      fill="currentColor"
    />
    <path
      d="M574.7 259.3H414.6C410 259.3 406.2 255.5 406.2 250.9V236.5C406.2 231.9 410 228.1 414.6 228.1H574.7C579.3 228.1 583.1 231.9 583.1 236.5V250.9C583.1 255.7 579.3 259.3 574.7 259.3ZM414.6 229.8C410.8 229.8 407.8 232.9 407.8 236.7V251C407.8 254.8 410.8 257.9 414.6 257.9H574.7C578.5 257.9 581.5 254.8 581.5 251V236.6C581.5 232.8 578.5 229.7 574.7 229.7H414.6V229.8Z"
      fill="black"
    />
    <path
      d="M574.7 324.499H414.6C410 324.499 406.2 320.699 406.2 316.099V301.699C406.2 297.099 410 293.299 414.6 293.299H574.7C579.3 293.299 583.1 297.099 583.1 301.699V315.999C583.1 320.699 579.3 324.499 574.7 324.499ZM414.6 294.799C410.8 294.799 407.8 297.899 407.8 301.699V315.999C407.8 319.799 410.8 322.899 414.6 322.899H574.7C578.5 322.899 581.5 319.799 581.5 315.999V301.599C581.5 297.799 578.5 294.699 574.7 294.699H414.6V294.799Z"
      fill="black"
    />
    <path
      d="M574.7 389.599H414.6C410 389.599 406.2 385.799 406.2 381.199V366.799C406.2 362.199 410 358.399 414.6 358.399H574.7C579.3 358.399 583.1 362.199 583.1 366.799V381.199C583.1 385.799 579.3 389.599 574.7 389.599ZM414.6 359.899C410.8 359.899 407.8 362.999 407.8 366.799V381.199C407.8 384.999 410.8 388.099 414.6 388.099H574.7C578.5 388.099 581.5 384.999 581.5 381.199V366.799C581.5 362.999 578.5 359.899 574.7 359.899H414.6Z"
      fill="black"
    />
    <path
      d="M304.8 343.599H169.2C164.6 343.599 160.8 339.799 160.8 335.199V229.599C160.8 224.999 164.6 221.199 169.2 221.199H304.8C309.4 221.199 313.2 224.999 313.2 229.599V335.199C313.1 339.799 309.4 343.599 304.8 343.599Z"
      fill="#D4D6DB"
    />
    <path
      d="M315.9 332.399H180.3C175.7 332.399 171.9 328.599 171.9 323.999V218.399C171.9 213.799 175.7 209.999 180.3 209.999H315.9C320.5 209.999 324.3 213.799 324.3 218.399V323.999C324.2 328.599 320.5 332.399 315.9 332.399Z"
      fill="white"
    />
    <path
      d="M315.9 333.099H180.3C175.3 333.099 171.2 328.999 171.2 323.899V218.399C171.2 213.299 175.3 209.199 180.3 209.199H315.9C320.9 209.199 325 213.299 325 218.399V323.999C325 328.999 320.9 333.099 315.9 333.099ZM180.3 210.799C176.1 210.799 172.8 214.199 172.8 218.399V323.999C172.8 328.199 176.1 331.599 180.3 331.599H315.9C320.1 331.599 323.4 328.199 323.4 323.999V218.399C323.4 214.199 320.1 210.799 315.9 210.799H180.3Z"
      fill="black"
    />
    <path
      d="M303.1 250.299V296.999C303.1 300.599 300.2 303.499 296.6 303.499H214.5C210.9 303.499 208 300.499 208 296.999V250.299C208 246.699 210.9 243.799 214.5 243.799H296.6C300.2 243.699 303.1 246.699 303.1 250.299Z"
      fill="white"
    />
    <path
      d="M288.3 235.399V281.999C288.3 285.599 285.4 288.499 281.8 288.499H199.7C196.1 288.499 193.2 285.499 193.2 281.999V235.299C193.2 231.699 196.1 228.799 199.7 228.799H281.8C285.4 228.799 288.3 231.699 288.3 235.399Z"
      fill="#D4D6DB"
    />
    <path
      d="M296.6 303.499H214.5C210.9 303.499 208 300.499 208 296.999V250.299C208 246.699 210.9 243.799 214.5 243.799H296.6C300.2 243.799 303.1 246.799 303.1 250.299V296.999C303.1 300.599 300.2 303.499 296.6 303.499Z"
      fill="white"
    />
    <path d="M303 256.299H208V268.099H303V256.299Z" fill="currentColor" />
    <path
      d="M296.6 304.299H214.5C210.5 304.299 207.3 300.999 207.3 296.999V250.299C207.3 246.299 210.5 242.999 214.5 242.999H296.6C300.6 242.999 303.8 246.299 303.8 250.299V296.999C303.8 300.999 300.6 304.299 296.6 304.299ZM214.5 244.599C211.4 244.599 208.9 247.099 208.9 250.299V296.999C208.9 300.199 211.4 302.699 214.5 302.699H296.6C299.7 302.699 302.2 300.199 302.2 296.999V250.299C302.2 247.099 299.7 244.599 296.6 244.599H214.5Z"
      fill="black"
    />
    <path
      d="M291.4 283.699H240.9C240.5 283.699 240.1 283.399 240.1 282.899C240.1 282.399 240.4 282.099 240.9 282.099H291.4C291.8 282.099 292.2 282.399 292.2 282.899C292.2 283.399 291.8 283.699 291.4 283.699Z"
      fill="black"
    />
    <path
      d="M288.5 293.998H256.9C256.5 293.998 256.1 293.698 256.1 293.198C256.1 292.698 256.4 292.398 256.9 292.398H288.5C288.9 292.398 289.3 292.698 289.3 293.198C289.4 293.698 289 293.998 288.5 293.998Z"
      fill="black"
    />
    <path
      d="M737.1 296.7H658.1C652.2 296.7 647.5 292 647.5 286V206.3C647.5 200.4 652.2 195.6 658.1 195.6H737.1C743 195.6 747.7 200.3 747.7 206.3V286C747.6 292 742.9 296.7 737.1 296.7Z"
      fill="white"
    />
    <path
      d="M723.3 306.7H644.3C638.4 306.7 633.7 302 633.7 296V216.3C633.7 210.4 638.4 205.6 644.3 205.6H723.3C729.2 205.6 733.9 210.3 733.9 216.3V296C734 301.9 729.2 306.7 723.3 306.7Z"
      fill="#D4D6DB"
    />
    <path
      d="M737.1 296.7H658.1C652.2 296.7 647.5 292 647.5 286V206.3C647.5 200.4 652.2 195.6 658.1 195.6H737.1C743 195.6 747.7 200.3 747.7 206.3V286C747.6 292 742.9 296.7 737.1 296.7Z"
      fill="currentColor"
    />
    <path
      d="M737.1 297.5H658.1C651.8 297.5 646.7 292.3 646.7 286V206.3C646.7 200 651.8 194.8 658.1 194.8H737.1C743.4 194.8 748.5 200 748.5 206.3V286.1C748.4 292.4 743.3 297.5 737.1 297.5ZM658 196.4C652.6 196.4 648.2 200.8 648.2 206.3V286C648.2 291.5 652.6 295.9 658 295.9H737C742.4 295.9 746.8 291.5 746.8 286V206.3C746.8 200.8 742.4 196.4 737 196.4H658Z"
      fill="black"
    />
    <path
      d="M732.9 246.2C732.9 265.9 717 281.9 697.5 281.9C677.9 281.9 662.1 265.9 662.1 246.2C662.1 226.5 678 210.5 697.5 210.5C717.1 210.5 732.9 226.4 732.9 246.2Z"
      fill="white"
    />
    <path d="M708.3 222.1H686.6V246.2H708.3V222.1Z" fill="currentColor" />
    <path
      d="M697.5 270.2L677.8 244.8C690.9 244.8 704.1 244.8 717.1 244.8L697.5 270.2Z"
      fill="currentColor"
    />
    <path
      d="M697.5 271C697.3 271 697.1 270.9 696.9 270.7L677.2 245.3C677 245.1 677 244.8 677.1 244.5C677.2 244.2 677.5 244.1 677.8 244.1H685.9V222.3C685.9 221.9 686.2 221.5 686.7 221.5H708.4C708.8 221.5 709.2 221.8 709.2 222.3V244.1H717.4C717.7 244.1 717.9 244.3 718.1 244.5C718.2 244.8 718.2 245.1 718 245.3L698.3 270.7C697.9 270.9 697.7 271 697.5 271ZM679.4 245.5L697.5 268.9L715.6 245.5H708.3C707.9 245.5 707.5 245.2 707.5 244.7V222.9H687.5V244.7C687.5 245.1 687.2 245.5 686.7 245.5H679.4Z"
      fill="black"
    />
    <path
      d="M697.5 282.7C677.5 282.7 661.3 266.3 661.3 246.2C661.3 226.1 677.5 209.7 697.5 209.7C717.5 209.7 733.7 226.1 733.7 246.2C733.7 266.3 717.5 282.7 697.5 282.7ZM697.5 211.2C678.5 211.2 662.9 226.8 662.9 246.1C662.9 265.3 678.4 281 697.5 281C716.6 281 732.1 265.4 732.1 246.1C732.1 226.9 716.5 211.2 697.5 211.2Z"
      fill="black"
    />
    <path
      d="M852.1 384L779.2 379.7L748.4 572.3L817.7 583.2L818.5 590.1C841.6 588.1 864.8 586.1 888 584.1C877.5 517.7 869 448.9 852.1 384Z"
      fill="#D4D6DB"
    />
    <path
      d="M796.4 264.3C794.6 271.9 792.2 274.6 790.1 275.7C788.7 276.3 788.6 276.6 787.6 277.6C784.6 280.7 783.5 285.5 784.3 289.7C784.5 290.9 785.1 294.3 788 297.1C789.7 298.8 791.1 299.1 797.1 301C806.8 304.2 806 304.2 809.4 305C813 305.8 816.4 306.4 820.7 306.8C830.1 307.9 834.9 308.4 840 307.1C846.5 305.5 856.6 301.2 857.2 294.9C857.4 292.4 855.9 289.6 855 287.8C853 284.1 851.3 284.1 850.4 281.6C848.5 276.3 852.7 270.6 851.9 265.1C851.1 259.6 847.5 260.1 844.8 252.6C842.7 246.8 844.4 245.6 842.7 240.5C839.4 230.3 829.5 225.4 829 225.2C827.1 224.4 818.5 220.2 809.5 224.3C804.6 226.4 801.7 230 800.3 231.9C803 231.8 805.6 231.6 808.4 231.5C814.2 240 819.9 248.6 825.8 257L796.4 264.3Z"
      fill="currentColor"
    />
    <path
      d="M825.7 257C819.9 248.5 814.2 239.9 808.3 231.5C805.8 231.8 803.3 232 800.7 232.3C798.9 236.4 797 241.5 795.7 247.5C794.2 253.4 793.6 258.8 793.3 263.3C793.4 264.4 793.8 268 797 271.2C799.7 273.9 802.9 274.5 803.9 274.7C803.8 275.6 803.7 276.6 803.6 277.4C803.8 278.3 803.9 279.2 804.1 280.1C812.1 278.7 819.9 277.3 827.8 275.9C828 272.3 828.2 268.7 828.4 265.1C830.6 266.3 833.2 265.9 835 264.5C837.4 262.4 836.9 259.1 836.8 258.6C836.7 258.1 836.3 255.3 833.9 254.1C832.2 253.3 830.7 253.5 830.2 253.6C827.2 254.2 825.9 256.7 825.7 257Z"
      fill="white"
    />
    <path
      d="M818.6 590.9C818.2 590.9 817.9 590.6 817.9 590.2L817.2 584L748.5 573.2C748.3 573.2 748.1 573.1 748 572.9C747.9 572.7 747.8 572.5 747.9 572.3L778.7 379.7C778.8 379.3 779.1 379.1 779.5 379.1L852.4 383.4C852.7 383.4 853 383.6 853.1 384C866.4 435.1 874.5 489.1 882.5 541.3C884.6 555.3 886.8 569.9 889.1 584.2C889.1 584.4 889.1 584.6 889 584.8C888.9 585 888.7 585.1 888.5 585.1L818.6 590.9ZM749.3 571.7L817.8 582.5C818.1 582.6 818.4 582.8 818.4 583.2L819.1 589.2L887 583.3C884.8 569.4 882.6 555.1 880.5 541.3C872.7 489.4 864.5 435.6 851.3 384.8L779.6 380.6L749.3 571.7Z"
      fill="black"
    />
    <path
      d="M794.8 579.1L789.8 604.7H730.3V601L747.1 591.5L752.2 572.4L794.8 579.1Z"
      fill="black"
    />
    <path
      d="M873.2 586.1C872.8 586.1 872.5 585.8 872.5 585.5L841 383.6C840.9 383.2 841.2 382.8 841.6 382.7C842 382.6 842.4 382.9 842.5 383.3L874 585.2C874.1 585.6 873.8 586 873.4 586.1C873.3 586.1 873.2 586.1 873.2 586.1Z"
      fill="black"
    />
    <path
      d="M817.7 584C817.3 584 817 583.7 817 583.3L810.9 410.9C810.9 410.5 811.2 410.1 811.6 410.1C812 410.1 812.4 410.4 812.4 410.8L818.5 583.2C818.6 583.6 818.1 584 817.7 584Z"
      fill="black"
    />
    <path
      d="M864.7 586V604.6H806.7C806.8 603.6 806.9 602.7 807.1 601.9C810.1 600.6 813.2 599.4 816.2 598.1C816.9 595.4 817.8 592.7 818.5 590L864.7 586Z"
      fill="black"
    />
    <path
      d="M725.1 283.3C725.4 283.1 725.7 282.9 726 282.8C727.3 282.1 728.5 281.2 729.8 280.5C733 278.5 736.3 276.5 739.5 274.5L760.3 314.1L791.4 289.1L803.6 282.1L803.4 277.5L828.2 275.7L828.4 282.1C835.3 284.7 842.3 287.5 849.2 290.1C851.9 291.6 856 294.3 859.8 298.9C864.6 304.7 866.6 310.7 867.3 314L885.3 413L855.3 416L850.3 383.9L778.9 379.7L786.9 333.2L773.6 348.5C772.3 349.2 764.6 353.7 755.4 350.6C748.5 348.3 744.9 343.1 743.9 341.5C735.1 323.7 726.4 305.7 717.6 287.9C717.6 287.9 720.3 286.3 723.8 284.1L725.1 283.3Z"
      fill="black"
    />
    <path
      d="M855.6 416.7C855.2 416.7 854.9 416.4 854.9 416.1L850 384.6L779.3 380.4C779.1 380.4 778.9 380.3 778.8 380.1C778.7 379.9 778.6 379.7 778.6 379.5L786.1 335.7L774.4 348.9C774.3 349 774.3 349 774.2 349C772.1 350.3 764.5 354.2 755.4 351.2C748.8 349 745 344.3 743.5 341.8L717 288.2C716.8 287.9 717 287.4 717.3 287.1L739.2 273.7C739.4 273.6 739.6 273.6 739.8 273.6C740 273.7 740.2 273.8 740.3 274L760.7 312.7L791 288.3C791 288.3 791.1 288.3 791.1 288.2L802.9 281.4L802.7 277.3C802.7 276.9 803 276.6 803.4 276.5L828.2 274.7C828.4 274.7 828.6 274.8 828.8 274.9C829 275 829 275.2 829 275.4L829.2 281.3L849.6 289.1H849.7C853.9 291.4 857.5 294.4 860.6 298.1C864.3 302.5 867 307.9 868.3 313.5L886.3 412.5C886.3 412.7 886.3 412.9 886.2 413.1C886.1 413.3 885.9 413.4 885.7 413.4L855.6 416.7C855.7 416.7 855.6 416.7 855.6 416.7ZM780.2 378.9L850.7 383.1C851.1 383.1 851.3 383.4 851.4 383.7L856.3 415L884.7 412.2L866.8 314C865.5 308.6 862.9 303.4 859.4 299.2C856.5 295.7 853 292.9 849 290.7L828.2 282.7C827.9 282.6 827.7 282.3 827.7 282L827.5 276.4L804.3 278.1L804.4 282C804.4 282.3 804.3 282.5 804 282.7L791.9 289.7L760.8 314.6C760.6 314.7 760.4 314.8 760.2 314.7C760 314.7 759.8 314.5 759.7 314.3L739.3 275.5L725.6 283.9L724.3 284.6L718.8 288L744.7 341C746.2 343.3 749.7 347.6 755.8 349.7C764.1 352.4 771.1 349 773.2 347.7L786.7 332.6C786.9 332.4 787.3 332.3 787.6 332.4C787.9 332.5 788.1 332.9 788 333.2L780.2 378.9Z"
      fill="black"
    />
    <path d="M842.3 334.4L843.9 334.2L851.4 383.8L849.8 384L842.3 334.4Z" fill="white" />
    <path d="M786.4 333L788.5 321.3L790 321.6L788 333.3L786.4 333Z" fill="white" />
    <path
      d="M763.2 319.4C763 319.4 762.7 319.3 762.6 319.1L759.7 314.5C759.5 314.1 759.6 313.7 759.9 313.4C760.3 313.2 760.7 313.3 760.9 313.6L763.8 318.2C764 318.6 763.9 319 763.6 319.3C763.5 319.4 763.4 319.4 763.2 319.4Z"
      fill="black"
    />
    <path
      d="M831.2 266.7C830.4 266.7 829.7 266.6 829.7 266.6C829.1 266.5 828.5 266.3 827.9 266C827.5 265.8 827.4 265.4 827.6 264.9C827.8 264.5 828.2 264.4 828.6 264.6C829 264.8 829.4 265 830 265.1C830 265.1 830.8 265.3 831.6 265.2C834.5 264.9 836.7 262.2 836.4 259.3C836.3 257.8 835.6 256.6 834.5 255.7C833.5 254.9 832 254.3 830.6 254.5C829.8 254.6 829 254.9 829 254.9C827.4 255.5 826.6 256.9 826.3 257.5C826.1 257.9 825.7 258 825.3 257.8C824.9 257.6 824.8 257.2 825 256.7C825.4 255.9 826.6 254.2 828.6 253.3C828.8 253.2 829.6 252.9 830.6 252.8C832.5 252.6 834.3 253.2 835.6 254.4C837.1 255.6 837.9 257.2 838.1 259.1C838.5 262.9 835.7 266.4 831.9 266.7C831.6 266.6 831.4 266.7 831.2 266.7Z"
      fill="black"
    />
    <path
      d="M827.7 277.6C827.2 277.6 826.9 277.2 827 276.8L827.7 265.2C827.7 264.8 828.1 264.5 828.5 264.5C828.9 264.5 829.2 264.9 829.2 265.3L828.5 276.9C828.4 277.3 828.1 277.6 827.7 277.6Z"
      fill="black"
    />
    <path
      d="M737.4 276.3C729.2 260.9 722.9 250.2 722.2 249.1C722.1 249 721.8 248.5 721.3 248.3C720.8 248.1 720.1 248.3 719.7 248.7C719.3 249.2 719.3 250 719.4 250.3C719.6 252.6 724.9 261.2 725.5 262.2C725.1 261.6 724.3 260.6 723.2 260.1C722.5 259.7 721.6 259.3 720.8 259.7C719.8 260.2 719.5 261.7 719.5 261.8C719.4 261.5 719 261 718.2 260.5C717.9 260.3 716.9 259.9 716.1 260.4C715.4 260.8 715.2 261.8 715.2 261.9C714.9 261.6 714 261.2 713.3 261.4C712.4 261.7 711.9 263 711.7 263.7C710.5 268.2 712.2 278.7 713.8 278.6C714.2 278.6 714.8 277.5 715.2 272.4C716.1 274.2 716.8 275.7 717.2 276.6C718.5 279.6 719 281.3 720.7 283.7C721.1 284.3 721.5 284.9 721.7 285.1L737.4 276.3Z"
      fill="white"
    />
    <path
      d="M721.7 285.7C721.5 285.7 721.3 285.6 721.1 285.4C720.7 284.9 720.4 284.5 719.9 283.9C718.6 282 718 280.5 717.2 278.5C717 278 716.8 277.4 716.5 276.8C716.4 277 716.3 277.2 716.2 277.4C716 277.6 715.6 278.1 714.9 278.2C714.6 278.2 714 278.2 713.3 277.7C711.2 275.8 709.4 268.4 709.5 265C709.5 264.1 709.8 263.2 710.2 262.5C710.5 262 711.4 260.5 713 260.3C713.5 260.2 714 260.3 714.6 260.5C714.8 260.1 715.1 259.8 715.5 259.4C716.3 259 717.3 259 718.3 259.5C718.5 259.6 718.7 259.7 718.8 259.8C719 259.3 719.4 258.9 720.1 258.5C720.9 258.2 721.7 258.3 722.3 258.6C720.2 254.9 718.4 251.5 718.2 249.9C718.2 249.5 718.1 248.5 718.7 247.8C719.2 247.2 720.3 247 721 247.2C721.4 247.3 721.9 247.6 722.4 248.4L722.8 249.1C727.8 257.6 732.8 266.5 737.6 275.6C737.8 276 737.7 276.4 737.3 276.7C736.9 276.9 736.5 276.8 736.3 276.4C731.5 267.3 726.6 258.5 721.5 250L721.1 249.3C720.9 249 720.7 248.9 720.6 248.8C720.4 248.7 720.1 248.8 719.9 249C719.7 249.2 719.7 249.7 719.8 249.9C720 251.7 723.7 257.8 725 260.2C725.4 260.8 725.6 261.4 725.7 261.6C725.9 262 725.8 262.4 725.4 262.7C725.1 262.9 724.6 262.8 724.4 262.5C723.9 261.7 723.1 261 722.4 260.6C721.7 260.2 721.1 260 720.7 260.2C720.1 260.5 719.9 261.7 719.9 261.7C719.9 262 719.6 262.3 719.3 262.4C719 262.5 718.7 262.3 718.5 262C718.3 261.7 718.1 261.4 717.6 261.1C717.4 261 716.8 260.7 716.2 260.9C715.8 261.1 715.7 261.7 715.7 261.8C715.7 262.1 715.5 262.3 715.3 262.4C715.1 262.5 714.8 262.5 714.6 262.3C714.4 262.1 713.9 261.8 713.2 261.9C712.3 262 711.7 263 711.5 263.4C711.2 263.9 711.1 264.6 711 265.2C710.8 269.2 713.3 276.7 714.6 276.7L714.8 276.5C715.5 275.7 715 273.8 714.4 272.5C714.2 272.1 714.4 271.7 714.7 271.4C715.1 271.2 715.5 271.4 715.7 271.7C716.4 273.1 717.1 274.5 717.7 275.9C718 276.5 718.2 277.2 718.5 277.8C719.3 279.7 719.9 281.1 721 282.9C721.3 283.3 721.6 283.8 722 284.3C722.3 284.6 722.2 285.1 721.9 285.5C722 285.7 721.8 285.7 721.7 285.7Z"
      fill="black"
    />
    <path
      d="M728.4 267.1C728.2 267.1 727.9 267 727.8 266.8L724.6 262.5C724.4 262.2 724.4 261.7 724.8 261.4C725.1 261.2 725.6 261.2 725.8 261.6L729 265.9C729.2 266.2 729.2 266.7 728.8 267C728.7 267.1 728.6 267.1 728.4 267.1Z"
      fill="black"
    />
    <path
      d="M725.3 269C725.1 269 724.8 268.9 724.7 268.7C723.7 267.1 722.5 265.6 721.2 264.3C720.6 263.6 719.8 262.9 719.1 262.2C718.8 261.9 718.8 261.5 719 261C719.3 260.7 719.7 260.7 720.2 260.9C720.9 261.5 721.7 262.3 722.4 263.1C723.8 264.6 724.9 266.2 726.1 267.7C726.3 268 726.2 268.5 725.9 268.8C725.6 269 725.5 269 725.3 269Z"
      fill="black"
    />
    <path
      d="M721.2 269.5C721 269.5 720.7 269.4 720.6 269.2C719.6 267.6 718.4 266.1 717.1 264.8C716.5 264.1 715.7 263.4 715 262.7C714.7 262.4 714.7 262 714.9 261.5C715.2 261.2 715.6 261.2 716.1 261.4C716.8 262 717.6 262.8 718.3 263.6C719.7 265.1 720.8 266.7 722 268.2C722.2 268.5 722.1 269 721.8 269.3C721.5 269.4 721.3 269.5 721.2 269.5Z"
      fill="black"
    />
    <path
      d="M715 273.1C714.7 273.1 714.3 272.9 714.3 272.5C714.2 272.1 714.1 271.8 713.9 271.3C713.8 271.1 713.6 270.8 713.5 270.6C713.3 270.3 713.3 269.8 713.7 269.5C714 269.3 714.5 269.3 714.7 269.7C714.9 270 715.1 270.3 715.2 270.7C715.4 271.1 715.6 271.6 715.7 272.2C715.8 272.6 715.5 273 715.1 273.1C715.1 273.1 715.1 273.1 715 273.1Z"
      fill="black"
    />
    <path
      d="M877 413.5C878.2 415.4 879.5 417.8 880.7 421C882.1 424.7 882.6 427.4 883.2 432C883.4 433.4 884.8 443.3 882.7 445.3C882.5 445.5 881.9 446.1 881.1 446C880.4 445.9 879.9 445.4 879.7 445.2C879.4 446.3 878.4 446.9 877.4 446.9C876.4 446.9 875.5 446.2 875.2 445.1C875.2 445.2 874.8 445.9 873.9 446C873.2 446.1 872.5 445.5 872.3 445.3C871.7 444.6 871.6 443.7 871.6 443.5C871.2 440.1 869.3 433.3 867 428.9C866.2 427.3 865.5 426.8 865.1 426.8C864.2 426.9 863.4 429.4 863.3 431.2C863.2 433.2 863.8 436.8 864.1 438.9C864.2 439.4 864.3 440 864.2 440.5C864.1 441 863.8 441.6 863.3 441.7C862.6 441.9 861.7 440.8 861.4 440.3C859.4 437.6 856.9 420.5 856.3 415.8C863.2 415.1 870.1 414.3 877 413.5Z"
      fill="white"
    />
    <path
      d="M877.5 447.8C877.5 447.8 877.3 447.8 877.5 447.8C876.5 447.8 875.6 447.3 875.1 446.6C874.8 446.8 874.5 446.9 874.2 446.9C872.9 447 872.2 446.2 872 445.8C871.5 445.2 871.2 444.5 871.1 443.6C870.7 440.5 868.9 433.7 866.5 429.4C865.7 427.9 865.3 427.7 865.3 427.7C865.1 427.9 864.5 429.4 864.3 431.4C864.2 433.2 864.7 436.4 865 438.5L865.1 439C865.2 439.6 865.3 440.3 865.2 440.9C865 441.7 864.4 442.5 863.6 442.7C862.3 443 861.4 441.5 861 441C859.2 438.5 857.1 426.1 855.7 416.2C855.7 416 855.7 415.8 855.8 415.6C855.9 415.4 856.1 415.3 856.3 415.3L877 412.9C877.3 412.9 877.6 413 877.7 413.3C879.2 415.7 880.4 418.3 881.4 420.9C882.8 424.6 883.3 427.2 883.9 432.1C884.4 435.6 885.4 443.9 883.2 446.1C882.9 446.4 882.2 447.2 881 447C880.6 447 880.3 446.9 880 446.7C879.3 447.2 878.4 447.8 877.5 447.8ZM875.2 444.4C875.3 444.4 875.3 444.4 875.2 444.4C875.6 444.4 875.8 444.7 875.9 445C876.1 445.6 876.6 446.2 877.4 446.2C878 446.2 878.8 445.8 878.9 445C879 444.7 879.2 444.5 879.5 444.5C879.8 444.5 880.1 444.5 880.2 444.8C880.3 445 880.6 445.3 881 445.3C881.4 445.3 881.8 445 881.9 444.8C883.3 443.5 882.9 437.3 882.2 432.1C881.5 427.5 881 424.8 879.7 421.2C878.9 418.8 877.7 416.5 876.4 414.2L856.9 416.4C858.8 429.4 860.6 438 861.8 439.7C862.5 440.8 862.8 440.8 862.8 440.8C862.9 440.8 863.1 440.6 863.1 440.3C863.2 440 863.1 439.5 863 439L862.9 438.5C862.5 436.2 862 433 862.2 431.1C862.2 430.6 862.6 426.2 864.6 425.9C865.6 425.8 866.4 426.7 867.3 428.4C869.8 432.9 871.6 439.9 872 443.2C872.1 443.7 872.2 444.1 872.5 444.6C872.6 444.8 873 445.1 873.3 445.1C873.7 445.1 873.9 444.6 873.9 444.6C874.6 444.5 874.9 444.4 875.2 444.4Z"
      fill="black"
    />
    <path
      d="M875.2 446C874.7 446 874.4 445.6 874.4 445.2C874.5 442.1 874.3 439.2 873.7 436.2C873.2 434.1 872.5 432 871.6 430C871.4 429.6 871.6 429.2 872 428.9C872.4 428.6 872.8 428.9 873 429.3C873.9 431.4 874.7 433.6 875.2 435.8C875.9 438.9 876.1 442 876 445.2C876 445.6 875.6 446 875.2 446Z"
      fill="black"
    />
    <path
      d="M879.7 446C879.3 446 878.9 445.7 878.9 445.2C878.9 442 878.7 438.8 878.2 435.7C877.9 433.6 877.4 431.6 876.7 429.6C876.6 429.2 876.8 428.8 877.2 428.7C877.6 428.6 878 428.8 878.1 429.2C878.7 431.3 879.3 433.4 879.6 435.5C880.1 438.8 880.4 442 880.3 445.3C880.5 445.6 880.1 446 879.7 446Z"
      fill="black"
    />
    <path
      d="M806.9 275.6C804.4 275.6 798.6 275.1 795.1 270.3C793.6 268.4 792.7 266 792.5 263.3V263.2C792.8 258.9 793.4 254.5 794.3 250.2C795.6 243.9 797.3 237.5 799.6 231.5C799.7 231.1 800.2 230.9 800.6 231.1C801 231.2 801.2 231.7 801 232.2C798.7 238.2 796.9 244.3 795.8 250.7C795 254.9 794.4 259.1 794.1 263.4C794.3 265 794.8 267.3 796.4 269.4C799.6 273.6 805 274 807.2 274C809.7 274 811.7 273.3 813.1 272.7C815.6 271.8 818 270.3 820 268.5C820.3 268.2 820.8 268.3 821.2 268.6C821.5 268.9 821.4 269.4 821.1 269.8C818.9 271.8 816.4 273.3 813.7 274.3C812.1 274.9 810 275.7 807.2 275.7C807 275.6 806.9 275.6 806.9 275.6Z"
      fill="black"
    />
    <path
      d="M803.5 264.5C803.3 264.5 803 264.5 802.8 264.4C800.7 264 799.1 262.1 799.2 260C799.2 259.8 799.3 259.5 799.6 259.4C799.8 259.3 800.1 259.3 800.3 259.4C801.3 259.8 802.5 260.1 803.5 260.2C804.5 260.3 805.7 260.2 806.7 259.9C806.9 259.8 807.2 259.9 807.4 260.1C807.6 260.3 807.7 260.5 807.6 260.8C807.6 260.9 807.6 260.9 807.6 261C807.4 263 805.6 264.5 803.5 264.5ZM800.9 261.2C801.2 262 802.1 262.7 803 262.9C804.2 263.1 805.2 262.6 805.8 261.6C805 261.7 804.2 261.7 803.4 261.7C802.5 261.7 801.7 261.5 800.9 261.2Z"
      fill="black"
    />
    <path
      d="M804.1 256.5C803.4 256.5 802.4 256.4 801.5 255.8C800.9 255.4 800.2 254.9 799.8 254.2C799.6 254 799.6 253.7 799.7 253.4L803 246.2C803.2 245.8 803.6 245.7 804 245.8C804.4 246 804.5 246.4 804.4 246.9L801.3 253.7C801.6 254 801.8 254.2 802.2 254.4C803.1 254.9 804.1 254.9 804.5 254.8C804.9 254.8 805.3 255 805.3 255.5C805.3 256 805.1 256.3 804.6 256.3C804.5 256.5 804.3 256.5 804.1 256.5Z"
      fill="black"
    />
    <path
      d="M810.9 250.9C810.8 250.9 810.8 250.9 810.7 250.9C810.4 250.8 810.1 250.7 809.9 250.4C809.7 250.1 809.7 249.8 809.7 249.5C809.8 248.9 810.4 248.3 811.2 248.4C811.5 248.5 811.8 248.6 812 248.9C812.2 249.2 812.2 249.5 812.2 249.8C812 250.5 811.4 250.9 810.9 250.9ZM810.5 249.5C810.5 249.6 810.5 249.7 810.6 249.7C810.6 249.8 810.7 249.8 810.8 249.8C811 249.8 811.1 249.7 811.2 249.5C811.2 249.4 811.2 249.3 811.1 249.3C811.1 249.2 811 249.2 810.9 249.2C810.7 249.2 810.6 249.4 810.5 249.5Z"
      fill="black"
    />
    <path
      d="M797.8 247.199C797.7 247.199 797.7 247.199 797.6 247.199C797.3 247.099 797 246.999 796.8 246.699C796.6 246.399 796.5 246.099 796.6 245.799C796.7 245.199 797.3 244.599 798.1 244.699C798.7 244.799 799.3 245.399 799.1 246.199C799 246.499 798.9 246.799 798.6 246.999C798.4 247.199 798 247.199 797.8 247.199ZM797.5 245.899C797.5 246.099 797.6 246.199 797.8 246.299C798 246.299 798.1 246.199 798.2 245.999L797.5 245.899ZM797.8 245.599C797.7 245.599 797.7 245.599 797.6 245.699C797.5 245.699 797.5 245.799 797.5 245.899L798.1 245.999C798.1 245.899 798.1 245.799 798 245.799C797.9 245.799 797.9 245.699 797.8 245.599Z"
      fill="black"
    />
    <path
      d="M825.7 257.9C825.5 257.9 825.2 257.8 825.1 257.6L808 232.3L800.4 232.7C800 232.7 799.6 232.4 799.6 232C799.6 231.6 799.9 231.2 800.3 231.2L808.4 230.8C808.7 230.8 808.9 230.9 809.1 231.1L826.5 256.6C826.7 256.9 826.6 257.4 826.3 257.7C826 257.8 825.8 257.9 825.7 257.9Z"
      fill="black"
    />
    <path
      d="M811.5 254.2C808.6 254.2 806.1 252 805.7 248.9C805.4 245.7 807.7 242.9 810.8 242.6C812.4 242.5 813.8 242.9 815 243.9C816.2 244.8 816.9 246.2 817.1 247.8C817.2 249.4 816.8 250.9 815.8 252C814.9 253.2 813.5 253.9 811.9 254.1C812 254.2 811.8 254.2 811.5 254.2ZM811.5 244.1C811.4 244.1 811.2 244.1 811.1 244.1C808.8 244.3 807.1 246.4 807.3 248.7C807.5 251 809.6 252.7 811.9 252.5C813.1 252.4 814 251.9 814.7 251C815.4 250.2 815.7 249 815.6 247.9C815.5 246.8 815 245.8 814.1 245.1C813.4 244.5 812.5 244.1 811.5 244.1Z"
      fill="black"
    />
    <path
      d="M797 251.4C794.1 251.4 791.6 249.2 791.2 246.1C790.9 242.9 793.2 240.1 796.3 239.8C799.4 239.5 802.3 241.8 802.6 245C802.7 246.6 802.3 248.1 801.3 249.2C800.4 250.4 799 251.1 797.4 251.3C797.3 251.4 797.2 251.4 797 251.4ZM797 241.4C796.9 241.4 796.7 241.4 796.6 241.4C794.3 241.6 792.6 243.7 792.8 246C792.9 247.2 793.4 248.1 794.3 248.8C795.2 249.5 796.3 249.9 797.3 249.7C798.5 249.6 799.4 249.1 800.1 248.2C800.8 247.4 801.1 246.2 801 245.1C801 243.1 799.1 241.4 797 241.4Z"
      fill="black"
    />
    <path
      d="M827.3 255.2C827.2 255.2 827.1 255.2 827 255.1L816.6 250.2C816.2 250 816.1 249.6 816.2 249.1C816.4 248.7 816.8 248.6 817.2 248.7L827.6 253.6C828 253.8 828.1 254.2 828 254.7C828 255.1 827.7 255.2 827.3 255.2Z"
      fill="black"
    />
    <path
      d="M805.9 247.4C805.8 247.4 805.7 247.4 805.6 247.4L802.5 246.2C802.1 246.1 801.9 245.6 802.1 245.1C802.2 244.7 802.7 244.5 803.1 244.7L806.2 245.9C806.6 246 806.8 246.5 806.6 247C806.5 247.2 806.2 247.4 805.9 247.4Z"
      fill="black"
    />
    <path
      d="M808.3 232.2C808.1 232.2 807.9 232.1 807.8 232C807.5 231.7 807.5 231.2 807.7 230.8C808.9 229.5 810.1 228.4 811.5 227.4C813.1 226.2 814.7 225.4 816.6 224.7C817 224.6 817.4 224.8 817.6 225.1C817.7 225.5 817.5 225.9 817.2 226.2C815.5 226.8 814 227.7 812.5 228.7C811.2 229.6 810 230.7 808.9 231.9C808.8 232.1 808.5 232.2 808.3 232.2Z"
      fill="black"
    />
    <path
      d="M366.1 540H259.5C254.2 540 249.9 535.7 249.9 530.3V455.8C249.9 450.4 254.2 446.1 259.5 446.1H366.1C371.4 446.1 375.7 450.4 375.7 455.8V530.3C375.7 535.6 371.4 540 366.1 540Z"
      fill="#D4D6DB"
    />
    <path
      d="M378.1 534.5H271.5C266.2 534.5 261.9 530.2 261.9 524.8V450.3C261.9 444.9 266.2 440.6 271.5 440.6H378.1C383.4 440.6 387.7 444.9 387.7 450.3V524.8C387.7 530.2 383.4 534.5 378.1 534.5Z"
      fill="white"
    />
    <path
      d="M307.9 495.199C307.9 491.899 307 489.299 305 487.199C303.1 485.199 299.8 483.599 294.9 482.199V470.499C296.7 471.399 297.7 472.899 298.1 474.999L306.5 474.099C306 470.999 304.6 468.599 302.5 466.799C300.6 465.099 298 464.099 294.9 463.599V460.499H294.2H289.4H288.6V463.599C285.3 463.999 282.4 465.299 280.3 467.399C278 469.599 276.8 472.499 276.8 475.699C276.8 478.999 277.8 481.699 279.8 483.999C281.7 486.099 284.6 487.799 288.5 488.899V501.399C287.7 500.899 286.8 500.199 286.1 499.299C285.2 498.099 284.4 496.799 284.1 495.099L275.4 495.899C276 499.899 277.6 502.999 280 505.199C282.2 507.199 285 508.499 288.5 508.999V514.599H289.3H294.1H294.8V508.799C298.8 508.199 301.9 506.699 304.2 504.299C306.6 501.899 307.9 498.799 307.9 495.199ZM286.1 478.299C285.4 477.399 285.1 476.399 285.1 475.299C285.1 474.099 285.5 473.099 286.3 472.099C286.9 471.399 287.7 470.799 288.5 470.399V480.099C287.5 479.599 286.8 478.999 286.1 478.299ZM298.3 500.199C297.5 501.099 296.3 501.799 295 502.099V490.699C296.6 491.199 297.8 491.899 298.6 492.699C299.4 493.599 300 494.799 300 496.199C299.7 497.799 299.2 499.099 298.3 500.199Z"
      fill="currentColor"
    />
    <path
      d="M366.6 461.299H328.3C327.9 461.299 327.5 460.999 327.5 460.499C327.5 459.999 327.8 459.699 328.3 459.699H366.6C367 459.699 367.4 459.999 367.4 460.499C367.4 460.999 367 461.299 366.6 461.299Z"
      fill="black"
    />
    <path
      d="M366.6 510.7H328.3C327.9 510.7 327.5 510.4 327.5 509.9C327.5 509.4 327.8 509.1 328.3 509.1H366.6C367 509.1 367.4 509.4 367.4 509.9C367.4 510.4 367 510.7 366.6 510.7Z"
      fill="black"
    />
    <path
      d="M351.2 494.2H328.3C327.9 494.2 327.5 493.9 327.5 493.4C327.5 492.9 327.8 492.6 328.3 492.6H351.2C351.6 492.6 352 492.9 352 493.4C352 493.9 351.6 494.2 351.2 494.2Z"
      fill="black"
    />
    <path
      d="M374 477.7H328.3C327.9 477.7 327.5 477.4 327.5 476.9C327.5 476.4 327.8 476.1 328.3 476.1H374C374.4 476.1 374.8 476.4 374.8 476.9C374.8 477.4 374.5 477.7 374 477.7Z"
      fill="black"
    />
    <path
      d="M378.1 535.3H271.5C265.7 535.3 261 530.6 261 524.7V450.2C261 444.4 265.7 439.6 271.5 439.6H378.1C383.9 439.6 388.6 444.3 388.6 450.2V524.7C388.6 530.6 383.9 535.3 378.1 535.3ZM271.5 441.4C266.6 441.4 262.6 445.4 262.6 450.4V524.9C262.6 529.9 266.6 533.9 271.5 533.9H378.1C383 533.9 387 529.9 387 524.9V450.4C387 445.4 383 441.4 378.1 441.4H271.5Z"
      fill="black"
    />
    <path
      d="M567 244.6H423.5C423.1 244.6 422.7 244.3 422.7 243.8C422.7 243.3 423 243 423.5 243H567C567.4 243 567.8 243.3 567.8 243.8C567.9 244.3 567.5 244.6 567 244.6Z"
      fill="black"
    />
    <path
      d="M578.1 110.1H530.6C530.2 110.1 529.8 109.8 529.8 109.3C529.8 108.8 530.1 108.5 530.6 108.5H578.1C578.5 108.5 578.9 108.8 578.9 109.3C579 109.8 578.6 110.1 578.1 110.1Z"
      fill="black"
    />
    <path
      d="M625.5 140.3H530.6C530.2 140.3 529.8 140 529.8 139.5C529.8 139 530.1 138.7 530.6 138.7H625.5C625.9 138.7 626.3 139 626.3 139.5C626.4 140 625.9 140.3 625.5 140.3Z"
      fill="black"
    />
    <path
      d="M578.1 170.4H530.6C530.2 170.4 529.8 170.1 529.8 169.6C529.8 169.1 530.1 168.8 530.6 168.8H578.1C578.5 168.8 578.9 169.1 578.9 169.6C578.9 170.1 578.6 170.4 578.1 170.4Z"
      fill="black"
    />
    <path
      d="M567 309.7H423.5C423.1 309.7 422.7 309.4 422.7 308.9C422.7 308.4 423 308.1 423.5 308.1H567C567.4 308.1 567.8 308.4 567.8 308.9C567.8 309.4 567.5 309.7 567 309.7Z"
      fill="black"
    />
    <path
      d="M434.3 382.1C434.1 382.1 433.9 382 433.8 381.9L419.4 367.3C419.1 367 419.1 366.5 419.4 366.1C419.7 365.7 420.2 365.8 420.6 366.1L435 380.7C435.3 381 435.3 381.5 435 381.9C434.7 382 434.5 382.1 434.3 382.1Z"
      fill="black"
    />
    <path
      d="M419.8 382.1C419.6 382.1 419.4 382 419.3 381.9C419 381.6 419 381.1 419.3 380.7L433.7 366.1C434 365.8 434.5 365.8 434.9 366.1C435.2 366.4 435.2 366.9 434.9 367.3L420.5 381.9C420.3 382 420.1 382.1 419.8 382.1Z"
      fill="black"
    />
    <path
      d="M460.9 382.1C460.7 382.1 460.5 382 460.4 381.9L446 367.3C445.7 367 445.7 366.5 446 366.1C446.3 365.7 446.8 365.8 447.2 366.1L461.6 380.7C461.9 381 461.9 381.5 461.6 381.9C461.3 382 461.1 382.1 460.9 382.1Z"
      fill="black"
    />
    <path
      d="M446.4 382.1C446.2 382.1 446 382 445.9 381.9C445.6 381.6 445.6 381.1 445.9 380.7L460.3 366.1C460.6 365.8 461.1 365.8 461.5 366.1C461.8 366.4 461.8 366.9 461.5 367.3L447 381.9C446.8 382 446.6 382.1 446.4 382.1Z"
      fill="black"
    />
    <path
      d="M487.4 382.1C487.2 382.1 487 382 486.9 381.9L472.5 367.3C472.2 367 472.2 366.5 472.5 366.1C472.8 365.8 473.3 365.8 473.7 366.1L488.1 380.7C488.4 381 488.4 381.5 488.1 381.9C487.8 382 487.6 382.1 487.4 382.1Z"
      fill="black"
    />
    <path
      d="M473 382.1C472.8 382.1 472.6 382 472.5 381.9C472.2 381.6 472.2 381.1 472.5 380.7L486.9 366.1C487.2 365.8 487.7 365.8 488.1 366.1C488.5 366.4 488.4 366.9 488.1 367.3L473.7 381.9C473.4 382 473.2 382.1 473 382.1Z"
      fill="black"
    />
    <path
      d="M513.9 382.1C513.7 382.1 513.5 382 513.4 381.9L499 367.3C498.7 367 498.7 366.5 499 366.1C499.3 365.7 499.8 365.8 500.2 366.1L514.6 380.7C514.9 381 514.9 381.5 514.6 381.9C514.3 382 514.1 382.1 513.9 382.1Z"
      fill="black"
    />
    <path
      d="M499.6 382.1C499.4 382.1 499.2 382 499.1 381.9C498.8 381.6 498.8 381.1 499.1 380.7L513.5 366.1C513.8 365.8 514.3 365.8 514.7 366.1C515 366.4 515 366.9 514.7 367.3L500.3 381.9C499.9 382 499.7 382.1 499.6 382.1Z"
      fill="black"
    />
    <path
      d="M540.5 382.1C540.3 382.1 540.1 382 540 381.9L525.6 367.3C525.3 367 525.3 366.5 525.6 366.1C525.9 365.7 526.4 365.8 526.8 366.1L541.2 380.7C541.5 381 541.5 381.5 541.2 381.9C540.9 382 540.7 382.1 540.5 382.1Z"
      fill="black"
    />
    <path
      d="M526 382.1C525.8 382.1 525.6 382 525.5 381.9C525.2 381.6 525.2 381.1 525.5 380.7L539.9 366.2C540.2 365.9 540.7 365.9 541.1 366.2C541.5 366.5 541.4 367 541.1 367.4L526.7 382C526.5 382 526.2 382.1 526 382.1Z"
      fill="black"
    />
    <path
      d="M567 382.1C566.8 382.1 566.6 382 566.5 381.9L552.1 367.3C551.8 367 551.8 366.5 552.1 366.1C552.4 365.7 552.9 365.8 553.3 366.1L567.7 380.7C568 381 568 381.5 567.7 381.9C567.5 382 567.3 382.1 567 382.1Z"
      fill="black"
    />
    <path
      d="M552.6 382.1C552.4 382.1 552.2 382 552.1 381.9C551.8 381.6 551.8 381.1 552.1 380.7L566.5 366.1C566.8 365.8 567.3 365.8 567.7 366.1C568 366.4 568 366.9 567.7 367.3L553.3 381.9C553 382 552.8 382.1 552.6 382.1Z"
      fill="black"
    />
    <path
      d="M957.7 605.499H156.3C155.9 605.499 155.5 605.199 155.5 604.699C155.5 604.199 155.8 603.899 156.3 603.899H957.7C958.1 603.899 958.5 604.199 958.5 604.699C958.5 605.199 958.1 605.499 957.7 605.499Z"
      fill="black"
    />
    <path
      d="M372.7 84.7002H291.8C285.5 84.7002 280.5 89.8002 280.5 96.1002V99.9002C280.5 108.1 280.5 116.3 280.5 124.5V125.2C280.5 131.5 285.5 136.6 291.8 136.6H372.7C379 136.6 384 131.5 384 125.2V96.2002C384 89.8002 378.8 84.7002 372.7 84.7002Z"
      fill="#D4D6DB"
    />
    <path
      d="M379.7 77.5H298.8C292.5 77.5 287.5 82.6 287.5 88.9V92.7C287.5 100.9 287.5 109.1 287.5 117.3V118C287.5 124.3 292.5 129.4 298.8 129.4H379.7C386 129.4 391 124.3 391 118V89C391.1 82.6 385.9 77.5 379.7 77.5Z"
      fill="white"
    />
    <path
      d="M379.7 130.1H298.8C292.1 130.1 286.7 124.6 286.7 117.9V88.9998C286.7 82.1998 292.1 76.7998 298.8 76.7998H379.7C386.4 76.7998 391.8 82.2998 391.8 88.9998V118C391.8 124.7 386.4 130.1 379.7 130.1ZM298.8 78.2998C292.9 78.2998 288.2 82.9998 288.2 88.9998V118C288.2 123.9 292.9 128.7 298.8 128.7H379.7C385.6 128.7 390.3 124 390.3 118V88.9998C390.3 83.0998 385.6 78.2998 379.7 78.2998H298.8Z"
      fill="black"
    />
    <path
      d="M320.6 108.5C323.362 108.5 325.6 106.216 325.6 103.4C325.6 100.583 323.362 98.2998 320.6 98.2998C317.839 98.2998 315.6 100.583 315.6 103.4C315.6 106.216 317.839 108.5 320.6 108.5Z"
      fill="currentColor"
    />
    <path
      d="M341.3 108.5C344.061 108.5 346.3 106.216 346.3 103.4C346.3 100.583 344.061 98.2998 341.3 98.2998C338.539 98.2998 336.3 100.583 336.3 103.4C336.3 106.216 338.539 108.5 341.3 108.5Z"
      fill="currentColor"
    />
    <path
      d="M361.9 108.5C364.662 108.5 366.9 106.216 366.9 103.4C366.9 100.583 364.662 98.2998 361.9 98.2998C359.139 98.2998 356.9 100.583 356.9 103.4C356.9 106.216 359.139 108.5 361.9 108.5Z"
      fill="currentColor"
    />
    <path
      d="M320.6 109.4C317.4 109.4 314.8 106.8 314.8 103.6C314.8 100.3 317.4 97.7998 320.6 97.7998C323.7 97.7998 326.4 100.4 326.4 103.6C326.3 106.7 323.8 109.4 320.6 109.4ZM320.6 99.1998C318.3 99.1998 316.4 101.1 316.4 103.4C316.4 105.7 318.3 107.6 320.6 107.6C322.9 107.6 324.8 105.7 324.8 103.4C324.7 101.1 322.9 99.1998 320.6 99.1998Z"
      fill="black"
    />
    <path
      d="M341.3 109.4C338.1 109.4 335.5 106.8 335.5 103.6C335.5 100.3 338.1 97.7998 341.3 97.7998C344.5 97.7998 347.1 100.4 347.1 103.6C347 106.7 344.4 109.4 341.3 109.4ZM341.3 99.1998C339 99.1998 337.1 101.1 337.1 103.4C337.1 105.7 339 107.6 341.3 107.6C343.6 107.6 345.5 105.7 345.5 103.4C345.5 101.1 343.6 99.1998 341.3 99.1998Z"
      fill="black"
    />
    <path
      d="M361.9 109.4C358.7 109.4 356.1 106.8 356.1 103.6C356.1 100.3 358.7 97.7998 361.9 97.7998C365 97.7998 367.7 100.4 367.7 103.6C367.7 106.7 365.1 109.4 361.9 109.4ZM361.9 99.1998C359.6 99.1998 357.7 101.1 357.7 103.4C357.7 105.7 359.6 107.6 361.9 107.6C364.2 107.6 366.1 105.7 366.1 103.4C366.2 101.1 364.3 99.1998 361.9 99.1998Z"
      fill="black"
    />
    <path
      d="M287.5 117.999C287.1 117.999 286.7 117.699 286.7 117.199V92.6994C286.7 92.2994 287 91.8994 287.5 91.8994C288 91.8994 288.3 92.1994 288.3 92.6994V117.299C288.3 117.699 287.9 117.999 287.5 117.999Z"
      fill="black"
    />
    <path
      d="M814.2 120.6H631.5C627.6 120.6 624.5 117.4 624.5 113.5V63.6C624.5 59.7 627.6 56.5 631.5 56.5H814.1C818 56.5 821.1 59.7 821.1 63.6V113.5C821.1 117.5 817.9 120.6 814.2 120.6Z"
      fill="#D4D6DB"
    />
    <path
      d="M825.2 109.4H642.7C638.8 109.4 635.7 106.2 635.7 102.3V52.3998C635.7 48.4998 638.8 45.2998 642.7 45.2998H825.3C829.2 45.2998 832.3 48.4998 832.3 52.3998V102.3C832.3 106.2 829.1 109.4 825.2 109.4Z"
      fill="white"
    />
    <path
      d="M825.2 110.1H642.7C638.4 110.1 635 106.6 635 102.3V52.3996C635 48.0996 638.5 44.5996 642.7 44.5996H825.3C829.6 44.5996 833 48.0996 833 52.3996V102.3C833 106.6 829.5 110.1 825.2 110.1ZM642.7 46.0996C639.2 46.0996 636.5 48.8996 636.5 52.2996V102.2C636.5 105.7 639.3 108.4 642.7 108.4H825.3C828.8 108.4 831.5 105.6 831.5 102.2V52.3996C831.5 48.8996 828.7 46.1996 825.3 46.1996C825.2 46.0996 642.7 46.0996 642.7 46.0996Z"
      fill="black"
    />
    <path
      d="M807.4 92.6994H660.6C656.7 92.6994 653.6 89.4994 653.6 85.5994V68.9994C653.6 65.0994 656.7 61.8994 660.6 61.8994H807.4C811.3 61.8994 814.4 65.0994 814.4 68.9994V85.5994C814.5 89.4994 811.2 92.6994 807.4 92.6994ZM660.6 63.4994C657.6 63.4994 655.2 65.8994 655.2 68.9994V85.5994C655.2 88.6994 657.6 91.0994 660.6 91.0994H807.4C810.4 91.0994 812.8 88.6994 812.8 85.5994V68.9994C812.8 65.8994 810.4 63.4994 807.4 63.4994H660.6Z"
      fill="black"
    />
    <path
      d="M802.2 87.0996L795.9 80.7996C795.7 80.5996 795.7 80.1996 795.9 79.8996L797.3 78.4996C797.5 78.2996 797.9 78.2996 798.2 78.4996L804.5 84.7996C804.7 84.9996 804.7 85.3996 804.5 85.6996L803.1 87.0996C803 87.2996 802.5 87.2996 802.2 87.0996Z"
      fill="currentColor"
    />
    <path
      d="M793.5 69.0996C789.8 69.0996 786.9 72.0996 786.9 75.6996C786.9 79.2996 789.8 82.2996 793.5 82.2996C797.2 82.2996 800.1 79.2996 800.1 75.6996C800.1 72.0996 797.2 69.0996 793.5 69.0996ZM793.5 79.9996C791.2 79.9996 789.3 78.0996 789.3 75.7996C789.3 73.4996 791.2 71.5996 793.5 71.5996C795.8 71.5996 797.7 73.4996 797.7 75.7996C797.7 78.0996 795.8 79.9996 793.5 79.9996Z"
      fill="currentColor"
    />
    <path
      d="M772.2 86.6996H666.5C666.1 86.6996 665.7 86.3996 665.7 85.8996C665.7 85.3996 666 85.0996 666.5 85.0996H772.2C772.6 85.0996 773 85.3996 773 85.8996C773 86.2996 772.7 86.6996 772.2 86.6996Z"
      fill="black"
    />
    <path
      d="M730.6 460.4H566.6C561.8 460.4 557.8 464.4 557.8 469.3V507C557.8 511.9 561.8 515.9 566.6 515.9H730.6C735.4 515.9 739.4 511.9 739.4 507V469.3C739.4 464.4 735.4 460.4 730.6 460.4Z"
      fill="#D4D6DB"
    />
    <path
      d="M738.1 452.8H574.1C569.3 452.8 565.3 456.8 565.3 461.7V499.4C565.3 504.3 569.3 508.3 574.1 508.3H738.1C742.9 508.3 746.9 504.3 746.9 499.4V461.7C746.9 456.7 743 452.8 738.1 452.8Z"
      fill="white"
    />
    <path
      d="M738.1 509.1H574.1C568.8 509.1 564.5 504.8 564.5 499.4V461.7C564.5 456.3 568.8 452 574.1 452H738.1C743.4 452 747.7 456.3 747.7 461.7V499.4C747.7 504.8 743.4 509.1 738.1 509.1ZM574.1 453.6C569.7 453.6 566 457.2 566 461.7V499.4C566 503.8 569.6 507.5 574.1 507.5H738.1C742.5 507.5 746.2 503.9 746.2 499.4V461.7C746.2 457.3 742.6 453.6 738.1 453.6H574.1Z"
      fill="black"
    />
    <path
      d="M719.4 496.8C710.6 496.8 703.5 489.6 703.5 480.8C703.5 471.9 710.6 464.8 719.4 464.8C728.2 464.8 735.3 472 735.3 480.8C735.3 489.6 728.2 496.8 719.4 496.8Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M719.4 480.6C717.2 480.6 715.5 478.8 715.5 476.7C715.5 474.6 717.3 472.8 719.4 472.8C721.5 472.8 723.3 474.6 723.3 476.7C723.3 478.9 721.6 480.6 719.4 480.6ZM719.4 482.6C723.3 482.6 726.5 485.8 726.5 489.7H712.3C712.3 485.8 715.5 482.6 719.4 482.6Z"
      fill="white"
    />
    <path
      d="M726.5 490.4H712.3C711.9 490.4 711.5 490.1 711.5 489.6C711.5 485.3 715.1 481.8 719.3 481.8C723.7 481.8 727.1 485.3 727.1 489.6C727.3 490.1 726.9 490.4 726.5 490.4ZM713.1 488.8H725.7C725.3 485.7 722.7 483.3 719.4 483.3C716.2 483.3 713.5 485.8 713.1 488.8ZM719.4 481.4C716.8 481.4 714.7 479.3 714.7 476.8C714.7 474.3 716.8 472.2 719.4 472.2C722 472.2 724.1 474.3 724.1 476.8C724.1 479.3 722 481.4 719.4 481.4ZM719.4 473.6C717.7 473.6 716.3 475 716.3 476.7C716.3 478.4 717.7 479.8 719.4 479.8C721.1 479.8 722.5 478.4 722.5 476.7C722.5 475 721.2 473.6 719.4 473.6Z"
      fill="black"
    />
    <path
      d="M687.7 492H584.5C584.1 492 583.7 491.7 583.7 491.2C583.7 490.7 584 490.4 584.5 490.4H687.7C688.1 490.4 688.5 490.7 688.5 491.2C688.5 491.7 688.1 492 687.7 492ZM671.8 481.6H584.5C584.1 481.6 583.7 481.3 583.7 480.8C583.7 480.3 584 480 584.5 480H671.8C672.2 480 672.6 480.3 672.6 480.8C672.6 481.3 672.2 481.6 671.8 481.6Z"
      fill="black"
    />
    <path
      d="M647.9 471.2H584.5C584.1 471.2 583.7 470.9 583.7 470.4C583.7 469.9 584 469.6 584.5 469.6H647.9C648.3 469.6 648.7 469.9 648.7 470.4C648.7 470.9 648.3 471.2 647.9 471.2Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M453.3 136.999C444.7 136.999 437.8 130.099 437.8 121.599C437.8 113.099 444.7 106.199 453.3 106.199C461.9 106.199 468.9 113.099 468.9 121.599C468.8 130.099 461.9 136.999 453.3 136.999ZM453.3 144.799C468.9 144.799 481.4 157.399 481.4 172.799H425.1C425.2 157.299 437.8 144.799 453.3 144.799Z"
      fill="white"
    />
    <path
      d="M481.5 173.6H425.2C424.8 173.6 424.4 173.3 424.4 172.8C424.4 157 437.4 144 453.3 144C469.3 144 482.3 156.9 482.3 172.8C482.3 173.2 481.9 173.6 481.5 173.6ZM426 172.1H480.7C480.3 157.4 468.1 145.7 453.3 145.7C438.5 145.6 426.4 157.4 426 172.1ZM453.3 137.9C444.3 137.9 437 130.6 437 121.6C437 112.6 444.3 105.3 453.3 105.3C462.3 105.3 469.6 112.6 469.6 121.6C469.6 130.6 462.3 137.9 453.3 137.9ZM453.3 106.9C445.1 106.9 438.5 113.4 438.5 121.6C438.5 129.8 445.1 136.3 453.3 136.3C461.5 136.3 468.1 129.8 468.1 121.6C468.1 113.5 461.5 106.9 453.3 106.9Z"
      fill="black"
    />
    <path
      d="M734.4 182.5C733.6 182.5 732.9 181.9 732.8 181.1L730.6 161C730.5 160.2 731.1 159.3 732 159.2C732.8 159.1 733.7 159.7 733.8 160.6L736 180.7C736.1 181.5 735.5 182.4 734.6 182.5C734.5 182.5 734.4 182.5 734.4 182.5Z"
      fill="black"
    />
    <path
      d="M744.4 184.1C744.1 184.1 743.8 184 743.6 183.9C742.9 183.4 742.7 182.4 743.2 181.7L762.7 151.9C763.2 151.2 764.2 151 764.9 151.5C765.6 152 765.8 153 765.3 153.7L745.8 183.5C745.3 183.9 744.9 184.1 744.4 184.1Z"
      fill="black"
    />
    <path
      d="M754.2 195C753.5 195 752.8 194.6 752.7 193.8C752.5 193 753 192.1 753.9 191.9L771.9 186.9C772.7 186.7 773.6 187.2 773.8 188.1C774 188.9 773.5 189.8 772.6 190L754.6 195C754.5 195 754.4 195 754.2 195Z"
      fill="black"
    />
    <path
      d="M501.4 453.6H438C437.6 453.6 437.2 453.3 437.2 452.8C437.2 452.3 437.5 452 438 452H501.4C501.8 452 502.2 452.3 502.2 452.8C502.2 453.3 501.9 453.6 501.4 453.6Z"
      fill="black"
    />
  </svg>
</template>

<script>
  export default {
    name: 'IoImage'
  }
</script>
