<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1080 650"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1080 0H0V650H1080V0Z" fill="white" />
    <g opacity="0.6">
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M537 109.6L155.8 494.2C155.4 493.5 155 492.9 154.7 492.3L534.5 109.2C535.3 109.3 536.1 109.4 537 109.6Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M702.5 58.7998L207.5 558.2C207 557.7 206.5 557.2 206 556.7L694 64.2998C696.8 62.4998 699.6 60.5998 702.5 58.7998Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M423.5 63.1994L151.4 337.699C151.6 336.599 151.7 335.499 151.8 334.299L421.3 62.3994C422 62.5994 422.8 62.8994 423.5 63.1994Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M285.4 45.8994L130 202.599C129.8 201.799 129.6 200.999 129.4 200.199L282.1 46.1994C283.2 46.0994 284.3 45.8994 285.4 45.8994Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M860.7 36.4998L291 611.3H288L858.4 35.7998C859.2 35.9998 859.9 36.2998 860.7 36.4998Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M967.1 179C966.7 180.4 966.3 181.8 965.9 183.3L541.6 611.3H538.6L967.1 179Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M941.5 85.6L420.4 611.3H417.5L940 84C940.5 84.5 941 85 941.5 85.6Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M938.1 357.9L686.9 611.3H684L937.7 355.3C937.8 356.2 937.9 357.1 938.1 357.9Z"
          fill="#D4D6DB"
        />
      </g>
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M950.2 493.3C950.2 494.3 950.1 495.4 950 496.4L836.2 611.3H833.3L950.2 493.3Z"
          fill="#D4D6DB"
        />
      </g>
    </g>
    <path
      d="M270.9 136.999L210.5 130.199C207.7 129.899 205.1 131.999 204.7 134.799L200.3 174.999C200 177.799 202.1 180.499 204.9 180.799L265.3 187.599C268.1 187.899 270.7 185.799 271.1 182.999L275.5 142.799C275.7 139.899 273.7 137.299 270.9 136.999Z"
      fill="#D4D6DB"
    />
    <path d="M307 188.6L277.8 163.4L311.8 145.1L307 188.6Z" fill="#D4D6DB" />
    <path
      d="M298.4 107.3C296.8 121.9 283.9 132.3 269.4 130.7C255 129.1 244.6 116 246.2 101.5C247.8 86.8999 260.7 76.4999 275.2 78.0999C289.6 79.6999 300 92.7999 298.4 107.3Z"
      fill="#D4D6DB"
    />
    <path
      d="M240.3 100.8C238.7 115.4 225.8 125.8 211.3 124.2C196.8 122.6 186.5 109.5 188.1 94.9999C189.7 80.3999 202.6 69.9999 217.1 71.5999C231.5 73.0999 241.8 86.1999 240.3 100.8Z"
      fill="#D4D6DB"
    />
    <path
      d="M692.1 570.4H394.4C384.1 570.4 375.6 561.9 375.6 551.5V76.8004C375.6 66.4004 384 57.9004 394.4 57.9004H692.1C702.4 57.9004 710.9 66.4004 710.9 76.8004V551.6C710.8 562 702.4 570.4 692.1 570.4Z"
      fill="#D4D6DB"
    />
    <path
      d="M702.2 560.201H404.5C394.2 560.201 385.7 551.701 385.7 541.301V66.5006C385.7 56.1006 394.1 47.6006 404.5 47.6006H702.2C712.5 47.6006 721 56.1006 721 66.5006V541.301C720.9 551.801 712.6 560.201 702.2 560.201Z"
      fill="white"
    />
    <path
      d="M702.2 560.9H404.5C393.7 560.9 385 552 385 541.3V66.5004C385 55.6004 393.8 46.9004 404.5 46.9004H702.2C713 46.9004 721.7 55.8004 721.7 66.5004V541.3C721.6 552.2 713 560.9 702.2 560.9ZM404.5 48.4004C394.6 48.4004 386.5 56.5004 386.5 66.5004V541.3C386.5 551.3 394.6 559.4 404.5 559.4H702.2C712.1 559.4 720.2 551.3 720.2 541.3V66.5004C720.2 56.5004 712.1 48.4004 702.2 48.4004H404.5Z"
      fill="black"
    />
    <path
      d="M517.3 184.9C515.9 182.9 512.8 178.4 512.9 172.2C513 167.9 514.6 164.7 514.9 164.1C516.6 161 517.8 161.1 520.2 158.2C524.7 152.6 522.8 148.9 527.7 142.8C529.4 140.7 530.9 139.4 531.7 138.9C538 134.2 544.7 133.8 546.3 133.8C556.2 133.5 565.9 139.3 569.9 147C572.9 152.6 573.6 160.2 573.7 161.4C574 164.9 573.5 165 574 167C575.4 172.7 579.5 173.4 583.6 180.2C585.4 183.3 586.9 185.7 586.8 188.9C586.6 195.3 579.5 199.9 577.5 201.1C558.7 213.2 526 197.5 517.3 184.9Z"
      fill="#131820"
    />
    <path d="M633.1 587.2L637.7 610.8H565.6V606.9L579.2 597.9L578.7 587.2H633.1Z" fill="#1B1818" />
    <path
      d="M503.8 586.101L497.8 611.601H426.4V607.401L441.8 597.601L445.1 586.101H503.8Z"
      fill="#131820"
    />
    <path
      d="M565.4 587.2H642.5L587.5 296H505.6L444.5 586.1H505.9L531.5 382.9L565.4 587.2Z"
      fill="currentColor"
    />
    <path
      d="M637.8 268.6C636.3 265.5 634.2 260.7 627 248.9C622.7 241.9 616.6 232.3 608.7 221C607.7 219.5 606.6 218 605.6 216.6C600.9 212.7 593.9 207.6 584.4 203.6C575.4 199.8 567.2 198.2 561.3 197.5L528.2 197.8C523.6 198 516.2 199 508 203C501.6 206.1 497 210 494.1 212.8C483.2 222.8 472.4 232.7 461.6 242.8C460.6 236.6 459.5 230.3 458.5 224.2C447.1 224.8 435.6 225.5 424.2 226.2C425.5 242.2 426.6 258.3 427.9 274.4C428.3 277.6 429.7 284.7 435.4 291.2C438.4 294.6 441.7 296.7 444 298C445.2 298.4 447.1 298.9 449.5 298.8C451.6 298.7 453.2 298.3 454.3 297.9C455.9 297.7 458 297.4 460.5 296.6C463.7 295.5 467 294.1 471.8 290.8C477.7 286.8 481.7 283 482.7 282.1C488.8 276.4 494.5 270.9 499.8 265.7C499.8 280.2 500.3 294.2 501.2 307.6C531.2 308.2 561.4 308.8 591.4 309.4C591.4 303.2 591.3 296.9 591.3 290.8C598.9 303.4 609.2 319.7 623 319.8C625.3 319.8 627.2 319.4 628.3 319.1C630.7 318.4 635.9 316.3 639.8 311.1C640.7 309.8 643.3 306.4 644.1 299.6C645.8 285.4 638.8 270.5 637.8 268.6Z"
      fill="#D4D6DB"
    />
    <path
      d="M599.1 234.001C599.2 233.701 601.4 225.801 597.5 222.701C597.2 222.401 596.2 221.801 595.3 221.901C593.1 222.101 591.1 225.701 590.6 231.001C589.9 234.101 591.9 236.901 594.4 237.201C596.2 237.401 598.3 236.201 599.1 234.001Z"
      fill="white"
    />
    <path
      d="M599.8 234.3L598.3 233.9C599.7 229.2 599.1 225.1 597 223.5C596.7 223.3 596.1 222.8 595.3 222.8C593.7 222.9 591.8 225.9 591.2 231.2L589.6 231C590.2 225.3 592.4 221.5 595 221.2C596.4 221.1 597.4 221.9 597.8 222.3C600.8 224.2 601.4 228.9 599.8 234.3Z"
      fill="black"
    />
    <path
      d="M642.5 588H565.4C565 588 564.7 587.7 564.7 587.4L531.6 388.4L506.6 586.2C506.6 586.6 506.2 586.9 505.9 586.9H444.5C444.3 586.9 444.1 586.8 443.9 586.6C443.8 586.4 443.7 586.2 443.7 586L502.4 307.5C502.5 307.1 502.9 306.8 503.3 306.9C503.7 307 504 307.4 503.9 307.8L445.4 585.3H505.2L530.7 382.8C530.7 382.4 531.1 382.2 531.4 382.1C531.8 382.1 532.1 382.4 532.2 382.7L565.9 586.3H641.4L589.1 309.3C589 308.9 589.3 308.5 589.7 308.4C590.1 308.3 590.5 308.6 590.6 309L643.3 587C643.3 587.2 643.3 587.4 643.1 587.6C642.9 587.9 642.7 588 642.5 588Z"
      fill="black"
    />
    <path
      d="M459.9 587.4C459.8 587.4 459.8 587.4 459.7 587.4C459.3 587.3 459 586.9 459.1 586.4L495.6 425C495.7 424.6 496.1 424.3 496.5 424.4C496.9 424.5 497.2 424.9 497.1 425.3L460.5 586.8C460.6 587.1 460.2 587.4 459.9 587.4Z"
      fill="black"
    />
    <path
      d="M629 587.4C628.7 587.4 628.3 587.2 628.3 586.8L569.9 309.5C569.8 309.1 570.1 308.7 570.5 308.6C570.9 308.5 571.3 308.8 571.4 309.2L629.7 586.5C629.8 586.9 629.5 587.3 629.1 587.5L629 587.4Z"
      fill="black"
    />
    <path
      d="M596.6 587.401C596.3 587.401 595.9 587.201 595.9 586.801L564.4 445.401C564.3 445.001 564.6 444.601 565 444.501C565.4 444.401 565.8 444.701 565.9 445.101L597.4 586.501C597.5 586.901 597.2 587.301 596.8 587.501C596.7 587.401 596.7 587.401 596.6 587.401Z"
      fill="black"
    />
    <path
      d="M531.5 383.801C531.1 383.801 530.8 383.501 530.8 383.201L527.5 360.901C527.4 360.501 527.7 360.101 528.1 360.001C528.5 359.901 528.9 360.201 529 360.601L532.3 382.901C532.4 383.301 532.1 383.701 531.7 383.801C531.6 383.801 531.6 383.801 531.5 383.801Z"
      fill="black"
    />
    <path
      d="M574.7 329.201C572.9 329.201 566.9 328.801 562.1 323.801C556.3 317.801 557.3 310.101 557.5 308.601C557.6 308.201 558 307.901 558.4 308.001C558.8 308.101 559.1 308.501 559 308.901C558.9 309.701 557.8 317.101 563.2 322.701C567.5 327.201 573 327.601 574.6 327.701C575 327.701 575.3 328.001 575.3 328.501C575.5 328.801 575.2 329.201 574.7 329.201Z"
      fill="black"
    />
    <path
      d="M498.4 327.6C498.1 327.6 497.8 327.4 497.7 327.1C497.6 326.7 497.8 326.3 498.2 326.2C501 325.4 503.5 323.9 505.5 321.9C510.4 316.7 510.3 310 510.1 308C510.1 307.6 510.4 307.2 510.8 307.2C511.2 307.2 511.6 307.5 511.6 307.9C511.8 310 512 317.4 506.6 323.1C504.4 325.3 501.7 327 498.6 327.8C498.5 327.6 498.4 327.6 498.4 327.6Z"
      fill="black"
    />
    <path
      d="M538.6 425.801C527.7 479.201 516.7 532.601 505.9 586.001C514.5 518.201 523 450.601 531.5 382.801C533.9 397.301 536.3 411.601 538.6 425.801Z"
      fill="#1B1818"
    />
    <path
      d="M591.3 310.1L501.1 308.3C500.7 308.3 500.4 308 500.4 307.6C499.4 296.9 498.8 286.2 498.7 275.4C498.6 266.2 498.8 256.9 499.3 247.7C499.3 247.3 499.7 247 500.1 247C500.5 247 500.8 247.4 500.8 247.8C500.3 256.9 500.1 266.2 500.2 275.3C500.3 285.7 500.8 296.2 501.9 306.6L590.6 308.3V291.2C590.6 290.8 590.9 290.4 591.4 290.4C591.9 290.4 592.2 290.7 592.2 291.2V309C592.2 309.2 592.1 309.4 592 309.5C591.7 310 591.5 310.1 591.3 310.1Z"
      fill="black"
    />
    <path
      d="M424.7 236.2C424.3 236.2 424 235.9 424 235.5C424 235.1 424.3 234.7 424.7 234.7L459.4 232.5C459.8 232.5 460.2 232.8 460.2 233.2C460.2 233.6 459.9 234 459.5 234L424.7 236.2Z"
      fill="black"
    />
    <path
      d="M449.5 299.601C446.9 299.601 444.4 299.201 441.9 298.001C431.2 293.301 427.9 279.601 426.9 273.901L423.3 226.201C423.3 226.001 423.4 225.801 423.5 225.601C423.6 225.401 423.8 225.401 424 225.301L458.3 223.301C458.7 223.301 459 223.601 459.1 223.901L465 265.601C465.1 266.001 464.8 266.401 464.4 266.401C464 266.501 463.6 266.201 463.6 265.801L457.8 224.801L425.1 226.701L428.6 273.501C429.4 278.801 432.6 291.901 442.6 296.301C454.8 301.701 470.6 290.201 476.5 285.901L476.6 285.801C478.7 284.301 482.1 281.501 487.4 276.601C491.5 272.801 495.4 268.701 498.9 264.501C499.2 264.201 499.6 264.101 500.1 264.401C500.4 264.701 500.5 265.101 500.2 265.601C496.6 269.901 492.7 274.001 488.6 277.801C483.4 282.801 479.8 285.601 477.7 287.101L477.6 287.201C472.4 291.001 460.7 299.601 449.5 299.601Z"
      fill="black"
    />
    <path
      d="M461.1 243.701C460.9 243.701 460.7 243.601 460.5 243.501C460.2 243.201 460.2 242.701 460.6 242.301L493.7 212.301C495.6 210.601 497.6 208.901 499.7 207.301C499.8 207.301 501.4 206.001 502.9 205.101C510.3 200.401 519.1 197.501 528.3 197.101L561.4 196.801C563.6 197.001 565.6 197.301 567.8 197.901C567.8 197.901 571.9 198.701 576.4 200.401C576.8 200.501 577 201.001 576.9 201.501C576.8 201.901 576.3 202.101 575.9 202.001C571.4 200.401 567.5 199.601 567.5 199.601C565.4 199.201 563.4 198.901 561.4 198.701L528.4 199.001C519.5 199.401 510.9 202.201 503.8 206.801C502.3 207.701 500.7 209.001 500.7 209.001C498.6 210.501 496.6 212.201 494.8 213.901L461.7 243.901C461.4 243.601 461.3 243.701 461.1 243.701Z"
      fill="black"
    />
    <path
      d="M560.9 174.2L561.3 186.2C562.1 194.6 563 203 563.7 211.4C561 219 553.4 223.9 545.5 222.9C537.7 222.1 531.4 215.8 530.3 207.8L531.2 193.2L531.8 187.6C532.6 188.2 534.1 189.2 536.1 189.6C537.5 189.9 538.7 189.9 539.6 189.9C536.8 191 534.1 192.1 531.2 193.2L560.9 174.2Z"
      fill="white"
    />
    <path
      d="M526.2 149.1C525.4 157.3 524.5 165.7 523.6 173.9C523.8 180.3 527.7 186.1 533.5 188.7C534.1 188.9 534.7 189.1 534.7 189.1C534.7 189.1 536.3 189.5 538 189.3C545.2 188.6 551.5 184.8 551.5 184.8C559.1 183.2 561 181.5 561.1 180.2C561.2 179.5 560.8 178.5 560.9 176.4C561 175.2 561.2 174.2 561.3 173.6C562.2 174.1 565.6 176 568.5 174.7C568.5 174.7 569.9 174.1 570.8 172.6C574.5 166.8 566.5 147.6 551.7 139.7C548.2 137.9 546.3 137.6 545.4 137.5C535.3 136.2 527.4 147.4 526.2 149.1Z"
      fill="white"
    />
    <path
      d="M566.7 172.2C566.5 172.2 566.4 172.2 566.3 172.1C566 171.9 565.9 171.4 566.1 171C566.8 169.9 566.9 168.6 566.5 167.9C566.3 167.6 566 167.4 565.8 167.4C565.4 167.3 565.2 166.9 565.3 166.5C565.4 166.1 565.8 165.9 566.2 166C566.9 166.2 567.6 166.6 568 167.3C568.7 168.6 568.5 170.6 567.5 172C567.2 172.1 567 172.2 566.7 172.2Z"
      fill="black"
    />
    <path
      d="M526.3 147.5C528.5 148.7 532.1 150.1 536.7 150.7C543.4 151.3 548.7 149.4 551.2 148.3C551.2 149.9 551.3 151.7 551.9 153.7C554 160.8 560.2 164.3 561.9 165.2C562.3 164.8 563.7 163.6 565.7 163.6C567.4 163.5 569.5 164.2 570.4 165.8C570.5 166 570.6 166.1 570.7 166.3C571 167.1 571.5 168.7 570.9 170.7C570.2 173 568.5 174.1 568.1 174.4C567.4 175.7 567.3 177.1 568 178C568.9 179.3 571.5 179.5 573.1 177.8C573.7 173.3 574.1 166.3 571.6 158.4C570.7 155.4 569 150.4 564.7 145.3C562.8 143.1 559.9 139.8 554.9 137.6C553 136.8 545.7 133.6 537.4 137C530.5 139.8 527.2 145.8 526.3 147.5Z"
      fill="#131820"
    />
    <path
      d="M547.4 223.8C547.2 223.8 547 223.8 546.7 223.8C546.3 223.8 545.9 223.8 545.4 223.7C542.6 223.4 539.9 222.4 537.6 220.9C532.4 217.4 529.3 211.1 529.8 204.4L530.8 187.7C530.8 187.3 531.2 187 531.6 187C532 187 532.3 187.4 532.3 187.8L531.3 204.5C530.9 210.6 533.6 216.3 538.4 219.5C540.6 220.9 543 221.8 545.6 222.1C546 222.1 546.3 222.2 546.8 222.2C551.1 222.4 555.1 220.7 558.1 217.5C561.2 214.1 562.8 209.6 562.4 204.9L560.6 186.2L560.2 174.2C560.2 174 560.2 173.9 560.4 173.7L560.9 173C561 172.8 561.2 172.7 561.4 172.7C561.6 172.7 561.8 172.8 562 172.9C562.8 173.6 563.8 174.2 564.9 174.3C566.3 174.4 567.5 174.1 568.6 173.2C569.7 172.3 570.3 171.2 570.4 169.8C570.5 168.4 570.1 167.1 569.1 166C568.2 164.9 566.8 164.3 565.4 164.3C564.1 164.3 563 164.7 562.1 165.6C561.9 165.8 561.5 165.9 561.2 165.7C558.3 164.1 553.1 160.4 551.2 154C550.8 152.6 550.6 151.2 550.5 149.6C546.1 151.4 541.3 152 536.7 151.6C533 151.2 529.4 150.1 526 148.4C525.6 148.2 525.5 147.8 525.7 147.3C525.9 146.8 526.3 146.8 526.7 147C529.9 148.7 533.3 149.6 536.7 150.1C541.5 150.6 546.3 149.8 550.8 147.8C551 147.7 551.3 147.7 551.5 147.9C551.7 148 551.8 148.3 551.8 148.5C551.8 150.3 552 152 552.5 153.7C554.2 159.3 558.6 162.7 561.3 164.3C562.5 163.5 563.8 162.9 565.3 163C567.1 163 568.9 163.8 570.1 165.2C571.4 166.6 572 168.5 571.8 170.3C571.6 172.1 570.8 173.7 569.4 174.7C568 175.9 566.4 176.3 564.6 176.2C563.4 176.1 562.5 175.7 561.6 175.1L562 186.5L563.8 205.2C564.3 210.3 562.5 215.2 559.1 218.9C556.1 221.9 551.9 223.8 547.4 223.8Z"
      fill="black"
    />
    <path
      d="M559.7 137.7C566.769 137.7 572.5 131.925 572.5 124.8C572.5 117.676 566.769 111.9 559.7 111.9C552.631 111.9 546.9 117.676 546.9 124.8C546.9 131.925 552.631 137.7 559.7 137.7Z"
      fill="#131820"
    />
    <path
      d="M538 179.901C537.9 179.901 537.7 179.901 537.5 179.901C535.2 179.701 533.3 177.701 533.3 175.401C533.3 175.201 533.4 174.901 533.6 174.801C533.8 174.701 534.1 174.601 534.3 174.701C535.5 175.101 536.7 175.301 538.1 175.301C539.4 175.301 540.5 175.101 541.7 174.701C541.9 174.601 542.2 174.701 542.4 174.801C542.6 174.901 542.7 175.201 542.7 175.401C542.7 175.501 542.7 175.501 542.7 175.601C542.5 178.101 540.4 179.901 538 179.901ZM535.2 176.401C535.6 177.501 536.6 178.201 537.7 178.301C539.1 178.401 540.3 177.701 540.8 176.501C539.9 176.701 538.9 176.801 538 176.801C537.1 176.801 536.1 176.701 535.2 176.401Z"
      fill="black"
    />
    <path
      d="M537.2 171.201C536.5 171.201 535.4 171.101 534.4 170.501C533.7 170.101 533 169.601 532.5 168.801C532.3 168.601 532.3 168.301 532.4 168.001L536 160.001C536.2 159.601 536.6 159.501 537 159.601C537.4 159.801 537.5 160.201 537.4 160.701L533.9 168.201C534.2 168.501 534.6 168.801 534.9 169.001C535.9 169.501 537 169.501 537.5 169.501C537.9 169.401 538.3 169.701 538.3 170.101C538.4 170.501 538.1 170.901 537.7 170.901C537.8 171.101 537.5 171.201 537.2 171.201Z"
      fill="black"
    />
    <path
      d="M543.9 164.5C543.5 164.5 543.2 164.3 543 164C542.8 163.7 542.7 163.4 542.7 163.1C542.7 162.8 542.9 162.5 543.1 162.3C543.4 162.1 543.7 162 544 162C544.3 162 544.6 162.2 544.8 162.4C545 162.7 545.1 163 545.1 163.3C545.1 163.6 544.9 163.9 544.7 164.1C544.5 164.3 544.2 164.5 543.9 164.5ZM543.7 163.2C543.7 163.3 543.7 163.3 543.8 163.4C543.8 163.5 543.9 163.5 544 163.5C544.1 163.5 544.1 163.5 544.2 163.4C544.3 163.4 544.3 163.3 544.3 163.2C544.3 163.1 544.3 163.1 544.2 163C544.2 162.9 544.1 162.9 544 162.9C543.9 162.9 543.9 162.9 543.8 163C543.7 163 543.7 163.1 543.7 163.2Z"
      fill="black"
    />
    <path
      d="M528.4 162.9H528.3C527.6 162.8 527 162.2 527.1 161.4C527.2 160.7 527.8 160.1 528.6 160.2C529.3 160.3 529.9 160.9 529.8 161.7C529.5 162.3 529 162.9 528.4 162.9ZM528.1 161.5C528.1 161.6 528.1 161.6 528.2 161.7C528.2 161.8 528.3 161.8 528.4 161.8C528.6 161.8 528.7 161.7 528.7 161.6L528.1 161.5ZM528.4 161.3C528.3 161.3 528.1 161.4 528.1 161.5L528.6 161.6C528.6 161.4 528.5 161.3 528.4 161.3Z"
      fill="black"
    />
    <path
      d="M525.4 157.301C525 157.301 524.7 157.001 524.6 156.601C524.6 156.201 524.9 155.801 525.3 155.801H530.9C531.3 155.801 531.6 156.101 531.7 156.501C531.7 156.901 531.4 157.301 531 157.301H525.4Z"
      fill="black"
    />
    <path
      d="M547.7 161C547.6 161 547.5 161 547.4 161L541.2 158.8C540.8 158.7 540.6 158.2 540.8 157.7C540.9 157.3 541.4 157.1 541.8 157.3L548 159.5C548.4 159.6 548.6 160.1 548.4 160.6C548.4 160.8 548.1 161 547.7 161Z"
      fill="black"
    />
    <path
      d="M539.7 190.601C537.5 190.601 535.3 190.201 533.3 189.301C527.1 186.801 523 180.601 522.8 173.901L525.4 149.001C525.4 148.601 525.8 148.301 526.2 148.301C526.6 148.301 526.9 148.701 526.9 149.101L524.3 173.901C524.5 180.001 528.2 185.501 533.8 187.801C539.7 190.201 546.6 188.701 551 184.101C551.3 183.801 551.8 183.801 552.2 184.101C552.5 184.401 552.5 184.901 552.2 185.301C548.8 188.801 544.3 190.601 539.7 190.601Z"
      fill="black"
    />
    <path
      d="M476.7 203.401L479 157.101L454.9 154.801C453.7 170.301 452.6 185.801 451.4 201.301C459.9 201.901 468.2 202.701 476.7 203.401Z"
      fill="black"
    />
    <path
      d="M477.2 204.001L450.8 201.801L454.3 154.101L479.6 156.501L477.2 204.001ZM452.1 200.701L476.1 202.701L478.4 157.501L455.5 155.301L452.1 200.701Z"
      fill="black"
    />
    <path
      d="M474.2 203.201L476.5 156.901L452.4 154.601C451.2 170.101 450.1 185.601 448.9 201.101C457.5 201.801 465.8 202.601 474.2 203.201Z"
      fill="#D4D6DB"
    />
    <path
      d="M474.2 204L449 201.9C448.6 201.9 448.3 201.5 448.3 201.1L451.8 154.6C451.8 154.4 451.9 154.2 452.1 154.1C452.3 154 452.5 153.9 452.7 153.9L476.8 156.2C477.2 156.2 477.5 156.6 477.5 157L475.2 203.3C475.2 203.5 475.1 203.7 474.9 203.8C474.6 203.9 474.4 204 474.2 204ZM449.9 200.4L473.5 202.4L475.7 157.6L453.1 155.5L449.9 200.4Z"
      fill="black"
    />
    <path
      d="M454.1 205.3C458.7 200.7 462.1 197.3 463.6 195.7C463.9 195.4 464.9 194.5 464.9 193.4C464.9 192.8 464.7 192 464.2 191.5C464.2 191.5 463.7 191 462.7 190.8C462.5 190.8 462.3 190.8 462.1 190.8C462.2 190.7 462.4 190.5 462.5 190.4C465 188 466.6 186.3 466.2 184.8C465.9 183.6 464.3 182.9 463.2 182.9C462.9 182.9 462.7 183 462.4 183.1C463.7 182 464.9 181 466.2 179.8C466.4 179.5 467.2 178.3 466.9 177C466.7 175.5 465.4 174.6 464.8 174.3C464.5 174.2 464.1 174.1 463.6 174.1C463.7 174 463.8 173.9 463.9 173.9C464.1 173.6 464.5 173 464.7 172C464.8 171.6 464.9 170.5 464.3 169.8C463.7 169.1 462.5 168.7 461.4 169.1C460.6 169.6 459.4 170.5 458.1 171.4C455.7 173.1 451.9 175.7 448.1 179.1C445.5 181.4 442.1 184.6 438.4 188.9C437.1 192.5 435.8 196.2 434.5 200C431.6 208.8 429.1 217.2 426.9 225.4C436.5 224.9 446.2 224.5 455.9 223.9C455.2 217.8 454.6 211.5 454.1 205.3Z"
      fill="white"
    />
    <path
      d="M426.8 226.301C426.7 226.301 426.7 226.301 426.6 226.301C426.2 226.201 426 225.801 426.1 225.401C428.4 216.901 431 208.301 433.7 199.901C435 196.201 436.2 192.401 437.6 188.801C437.6 188.701 437.7 188.601 437.7 188.601C440.7 185.001 444.1 181.701 447.4 178.701C451.3 175.301 455 172.701 457.4 170.901C458.8 170.001 459.9 169.101 460.7 168.601C460.8 168.601 460.8 168.501 460.9 168.501C462.4 168.101 463.9 168.401 464.7 169.401C465.5 170.501 465.3 171.601 465.2 172.201C465.1 173.001 464.8 173.801 464.3 174.401L464.2 174.501L455.4 181.901C455.1 182.201 454.6 182.101 454.2 181.801C453.9 181.501 454 181.001 454.3 180.601L463 173.301C463.2 173.001 463.5 172.501 463.6 171.801C463.7 171.401 463.8 170.601 463.4 170.201C463 169.701 462.2 169.601 461.4 169.801C460.6 170.301 459.5 171.101 458.2 172.001C455.8 173.701 452.1 176.301 448.3 179.701C445 182.501 441.8 185.801 438.8 189.301C437.5 192.901 436.2 196.601 435 200.301C432.2 208.601 429.7 217.201 427.4 225.701C427.4 226.101 427.1 226.301 426.8 226.301Z"
      fill="black"
    />
    <path
      d="M455.8 189.701C455.6 189.701 455.4 189.601 455.2 189.401C454.9 189.101 455 188.601 455.3 188.201L465 179.901C465.5 179.201 466 178.001 466 176.601C466 176.201 465.9 176.001 465.9 175.901C465.4 175.001 463.7 174.801 463.5 174.801C463.1 174.801 462.8 174.401 462.8 174.001C462.8 173.601 463.1 173.301 463.5 173.301C464.1 173.301 466.3 173.501 467.3 175.201C467.4 175.401 467.6 175.801 467.6 176.601C467.7 178.001 467.2 179.601 466.2 180.901C466.2 181.001 466.1 181.001 466.1 181.001L456.3 189.401C456.1 189.601 455.9 189.701 455.8 189.701Z"
      fill="black"
    />
    <path
      d="M457.6 195.4C457.4 195.4 457.2 195.3 457 195.1C456.7 194.8 456.8 194.3 457.1 193.9L464.6 187.5C464.6 187.5 465.5 186.6 465.3 185.4C465.1 184.3 463.9 183.6 462.8 183.6C462.4 183.6 462 183.3 462 182.9C462 182.5 462.3 182.1 462.7 182.1C464.7 182 466.5 183.3 466.9 185.2C467.3 187.2 465.6 188.7 465.6 188.8L458.1 195.2C457.9 195.3 457.7 195.4 457.6 195.4Z"
      fill="black"
    />
    <path
      d="M455.7 224.8C455.3 224.8 455 224.5 455 224.1L453.4 205.3C453.4 205.1 453.5 204.9 453.6 204.7L463.5 194.7C464.1 194.1 464.2 193.1 463.8 192.4C463.4 191.8 462.6 191.5 461.9 191.6C461.5 191.7 461.1 191.4 461 191C460.9 190.6 461.2 190.2 461.6 190.1C463 189.9 464.3 190.4 465.1 191.6C465.9 193 465.7 194.8 464.6 195.8L454.9 205.5L456.4 224C456.5 224.4 456.2 224.8 455.7 224.8Z"
      fill="black"
    />
    <path
      d="M464.6 165.7C464.4 165.7 464.1 165.7 463.9 165.6C462.6 165.2 461.9 163.8 462.3 162.5C462.7 161.2 464.1 160.5 465.3 160.9C466.6 161.3 467.3 162.7 466.9 164C466.7 164.6 466.3 165.2 465.7 165.5C465.3 165.6 465 165.7 464.6 165.7ZM464.6 162.4C464.3 162.4 463.9 162.6 463.8 163C463.7 163.4 463.9 163.9 464.3 164.1C464.7 164.3 465.2 164 465.3 163.6C465.4 163.2 465.2 162.7 464.8 162.5C464.8 162.4 464.7 162.4 464.6 162.4Z"
      fill="black"
    />
    <path
      d="M536.2 189.7C534.1 189.3 532.5 188.3 531.6 187.7C531.5 189.6 531.4 191.4 531.3 193.3C534.2 192.2 537.1 191.1 540 190C539.1 190.1 537.7 190.1 536.2 189.7Z"
      fill="black"
    />
    <path
      d="M577 261.2C576.8 261.2 576.5 261.1 576.4 260.9C576.2 260.6 576.3 260.1 576.6 259.8L599.8 244.9C600.2 244.7 600.6 244.8 600.8 245.1C601 245.4 600.9 245.9 600.6 246.2L577.4 261.1C577.4 261.2 577.2 261.2 577 261.2Z"
      fill="black"
    />
    <path
      d="M621.9 320.6C620 320.6 618.3 320.4 616.8 320.1C605.4 317.7 599.1 306.6 589.6 289.8C584.4 280.5 579.9 270.8 576.3 260.8C576.2 260.5 576.3 260.1 576.6 259.9L599.8 245C600.1 244.8 600.6 244.9 600.8 245.2C603.9 250.2 609 257.2 613.8 264.2C618.6 271.1 623.6 278.2 626.8 283.2C627 283.5 626.9 284 626.6 284.3C626.2 284.5 625.8 284.4 625.6 284.1C622.5 279.1 617.4 272.1 612.6 265.1C608 258.5 603.2 251.7 600 246.7L578 260.8C581.6 270.5 585.8 280 591 289C601.5 307.4 606.9 316.3 617.2 318.6C622.6 319.8 631.2 319.1 637.4 313.4C642.5 308.7 643.6 302.4 644.1 299.5C646.2 287.6 641.6 277.7 637.2 269.2C628 251.7 616.9 233.8 604.2 216.2C604 215.9 604 215.4 604.4 215.1C604.7 214.9 605.2 214.9 605.4 215.3C618.1 233 629.1 250.9 638.5 268.5C641.7 274.7 647.9 286.4 645.6 299.8C645.1 302.8 643.9 309.5 638.4 314.7C633.5 319 627.3 320.6 621.9 320.6Z"
      fill="black"
    />
    <path
      d="M581.4 271.501C581.2 271.501 580.9 271.401 580.8 271.101C580.6 270.701 580.7 270.301 581 270.001L606 254.201C606.3 254.001 606.8 254.101 607 254.401C607.2 254.701 607.1 255.201 606.8 255.501L581.8 271.301C581.7 271.401 581.6 271.501 581.4 271.501Z"
      fill="black"
    />
    <path
      d="M573.7 220.301L591.1 176.201C591.8 174.401 593.7 173.601 595.5 174.301L608.3 179.401C610.1 180.101 610.9 182.001 610.2 183.801L592.8 227.901C592.1 229.701 590.2 230.501 588.4 229.801L575.6 224.701C573.9 224.101 573.1 222.201 573.7 220.301Z"
      fill="#131820"
    />
    <path
      d="M580.3 223.401L596.1 178.501C596.7 176.701 598.6 175.801 600.4 176.401L613.3 181.001C615.1 181.601 616 183.501 615.4 185.301L599.6 230.301C599 232.101 597.1 233.001 595.3 232.401L582.4 227.801C580.7 227.201 579.8 225.101 580.3 223.401Z"
      fill="#D4D6DB"
    />
    <path
      d="M586.5 226L601 180.7C601.6 178.9 603.5 178 605.3 178.6L618.3 182.8C620.1 183.4 621 185.3 620.4 187.1L605.9 232.4C605.3 234.2 603.4 235.1 601.6 234.5L588.6 230.3C586.9 229.9 585.9 227.8 586.5 226Z"
      fill="currentColor"
    />
    <path
      d="M612 191.701C611.1 191.301 610.6 190.301 611.1 188.901C611.6 187.301 612.9 186.901 614.3 187.301C615.7 187.701 616.6 188.901 616.1 190.601C615.7 192.001 614.6 192.401 613.8 192.301L614.2 190.901C614.5 190.901 614.9 190.901 615.1 190.401C615.3 189.801 614.7 189.201 613.8 189.001C613.1 188.801 612.2 188.701 612 189.401C611.8 189.901 612.2 190.201 612.4 190.301L612 191.701Z"
      fill="white"
    />
    <path
      d="M610.2 191.801L615.2 193.401L614.8 194.801L609.8 193.201L610.2 191.801Z"
      fill="white"
    />
    <path
      d="M613.1 195.101L612.7 196.501L608.9 195.301L609.3 193.901L613.1 195.101ZM614.6 195.701L614.2 197.101L613.3 196.801L613.7 195.401L614.6 195.701Z"
      fill="white"
    />
    <path
      d="M608.9 200.001C608.3 199.701 607.7 199.101 608.2 197.801C608.6 196.501 609.7 196.201 610.7 196.601C611.7 197.001 612.4 198.001 612 199.101C611.6 200.501 610.4 200.601 610 200.401L610.4 199.101C610.6 199.101 610.9 199.101 611 198.901C611.1 198.601 610.9 198.401 610.2 198.001C609.5 197.801 609.2 197.801 608.9 198.101C608.9 198.401 608.9 198.501 609.2 198.701L608.9 200.001Z"
      fill="white"
    />
    <path
      d="M609.4 202.201C609.7 202.601 610 203.001 610.6 203.601L610.2 205.101L609.3 203.501C609 203.601 606.9 203.901 606.6 204.001L607 202.501C607.1 202.501 608.6 202.301 608.7 202.301L608.5 201.801L607.5 201.401L607.9 200.001L612.9 201.601L612.5 203.001L609.4 202.201Z"
      fill="white"
    />
    <path
      d="M606 208.201C605.6 208.001 605.1 208.001 605 207.901L605.4 206.601C605.5 206.601 605.6 206.601 605.7 206.601C605.5 206.401 605.5 206.001 605.6 205.401C605.8 204.601 606.5 204.301 607.2 204.601C608 204.801 608.2 205.801 607.8 206.801C607.8 206.901 607.7 207.101 607.6 207.201H607.8C608 207.201 608.2 207.301 608.4 207.001C608.4 206.701 608.4 206.601 608.2 206.401L608.6 205.101C609.2 205.301 609.6 205.901 609.3 207.201C608.9 208.401 608.4 208.701 607.3 208.501L606 208.201ZM607.1 207.001C607.1 206.901 607.1 206.801 607.1 206.701C607.3 206.301 607.1 206.101 606.9 205.901C606.7 205.901 606.5 205.901 606.4 206.101C606.2 206.501 606.5 206.601 606.9 206.801L607.1 207.001Z"
      fill="white"
    />
    <path
      d="M608.6 214.001L604.7 212.701C604.4 212.601 604 212.501 603.7 212.301L604.1 211.001C604.2 211.001 604.4 211.001 604.4 211.001C604.2 210.801 604.2 210.401 604.2 209.801C604.5 208.901 605.5 208.501 606.5 209.001C607.7 209.401 608.2 210.301 608 211.301C607.8 211.701 607.7 212.001 607.5 212.001L609.1 212.501L608.6 214.001ZM606.1 210.501C605.5 210.301 605.1 210.301 604.9 210.801C604.7 211.301 605.2 211.501 605.6 211.601C606.2 211.801 606.5 211.801 606.8 211.301C607.1 211.001 606.8 210.701 606.1 210.501Z"
      fill="white"
    />
    <path
      d="M607 214.301L606.6 215.701L602.9 214.501L603.3 213.101L607 214.301ZM608.3 214.801L607.9 216.201L607 215.901L607.4 214.501L608.3 214.801Z"
      fill="white"
    />
    <path
      d="M602.6 215.201L607.6 216.801L607.2 218.201L602.2 216.601L602.6 215.201Z"
      fill="white"
    />
    <path
      d="M601.9 217.401L606.9 219.001L606.5 220.401L601.5 218.801L601.9 217.401Z"
      fill="white"
    />
    <path
      d="M601.2 223.101C600.8 222.901 600.3 222.901 600.2 222.801L600.6 221.501C600.7 221.501 600.8 221.501 600.9 221.501C600.7 221.301 600.7 220.901 600.8 220.301C601 219.501 601.7 219.201 602.4 219.501C603.2 219.701 603.4 220.701 603 221.701C603 221.801 602.9 222.001 602.8 222.101H603C603.2 222.101 603.4 222.201 603.6 221.901C603.7 221.601 603.6 221.501 603.4 221.301L603.8 220.001C604.4 220.201 604.8 220.801 604.5 222.101C604.1 223.301 603.6 223.601 602.5 223.401L601.2 223.101ZM602.1 222.101C602.1 222.001 602.1 221.901 602.1 221.801C602.3 221.401 602.1 221.201 601.9 221.001C601.7 221.001 601.5 221.001 601.4 221.201C601.2 221.601 601.5 221.701 601.9 221.901L602.1 222.101Z"
      fill="white"
    />
    <path
      d="M607.2 184.901L607 185.401L607.9 185.701L607.5 187.101L606.6 186.801L606.4 187.401L605.5 187.201L605.7 186.601L604.1 186.101C603.9 186.101 603.8 186.101 603.6 186.301C603.6 186.301 603.6 186.401 603.6 186.501L602.9 186.201C602.9 186.001 603 185.701 603 185.401C603.2 184.601 603.8 184.501 604.5 184.701L606.2 185.201L606.4 184.701L607.2 184.901Z"
      fill="white"
    />
    <path
      d="M603.2 191.101C602 190.701 601.5 189.701 601.9 188.501C602.3 187.201 603.4 186.801 604.4 187.201C605.4 187.601 606.1 188.501 605.7 189.801C605.3 190.901 604.3 191.501 603.2 191.101ZM604 188.501C603.3 188.301 603 188.301 602.8 188.801C602.7 189.101 602.9 189.401 603.5 189.601C604.2 189.801 604.5 189.801 604.7 189.301C604.9 189.201 604.8 188.701 604 188.501Z"
      fill="white"
    />
    <path
      d="M601 192.501L599.4 192.001L599.8 190.601L603.8 192.001C604.1 192.101 604.5 192.201 604.7 192.301L604.3 193.701C604.2 193.701 604 193.701 603.9 193.601C604.1 193.801 604.2 194.201 604 194.901C603.8 195.601 603 196.201 601.7 195.701C600.3 195.301 599.9 194.301 600.2 193.501C600.6 192.801 600.8 192.601 601 192.501ZM602.3 194.201C602.9 194.401 603.2 194.301 603.5 193.901C603.7 193.501 603.4 193.301 602.8 193.101C602.2 192.901 601.9 193.001 601.6 193.301C601.3 193.601 601.6 194.001 602.3 194.201Z"
      fill="white"
    />
    <path
      d="M599.2 201.101C598.9 201.001 598.4 200.901 598 200.701L598.4 199.301C598.5 199.301 598.7 199.301 598.8 199.401C598.5 199.001 598.5 198.701 598.6 198.201C598.8 197.501 599.4 197.101 600.4 197.501L602.8 198.301L602.4 199.701L600.2 199.001C599.9 198.901 599.6 198.901 599.5 199.201C599.4 199.501 599.5 199.701 600 199.801L602 200.401L601.6 201.801L599.2 201.101Z"
      fill="white"
    />
    <path
      d="M597.6 202.901L596 202.401L596.4 201.001L600.4 202.401C600.7 202.501 601.1 202.601 601.3 202.701L601 204.001C600.9 204.001 600.7 204.001 600.6 203.901C600.8 204.101 600.9 204.501 600.7 205.201C600.5 205.901 599.7 206.501 598.4 206.001C597 205.601 596.6 204.501 596.9 203.801C597.1 203.101 597.4 203.001 597.6 202.901ZM598.9 204.501C599.5 204.701 599.8 204.601 600.1 204.201C600.3 203.801 600 203.601 599.4 203.401C598.8 203.201 598.5 203.301 598.2 203.601C598 204.001 598.2 204.201 598.9 204.501Z"
      fill="white"
    />
    <path d="M556.7 188.7L561.8 160.4L601.4 167.5L596.4 195.9L556.7 188.7Z" fill="white" />
    <path
      d="M596.8 196.4L556.2 189L561.4 159.7L602 167.1L596.8 196.4ZM557.3 188.3L596 195.4L600.9 168L562.2 160.9L557.3 188.3Z"
      fill="black"
    />
    <path d="M596.4 195.9L589.9 230.2L550.3 223L556.7 188.7L596.4 195.9Z" fill="white" />
    <path
      d="M588.7 202.601H588.6L573 199.901C572.5 199.801 572.2 199.401 572.3 198.801L572.4 198.301C572.4 198.101 572.6 197.901 572.7 197.801C572.9 197.701 573.1 197.601 573.3 197.701L588.9 200.401C589.1 200.401 589.3 200.601 589.5 200.801C589.7 201.001 589.7 201.201 589.6 201.401L589.5 201.901C589.5 202.201 589.1 202.601 588.7 202.601ZM573.3 198.801L588.6 201.501V201.201L573.3 198.501V198.801Z"
      fill="black"
    />
    <path
      d="M587.4 209H587.3L571.7 206.3C571.5 206.3 571.3 206.1 571.1 205.9C570.9 205.7 570.9 205.5 571 205.3L571.1 204.8C571.2 204.3 571.6 204 572.1 204.1L587.7 206.8C587.9 206.8 588.1 207 588.3 207.2C588.5 207.4 588.5 207.6 588.4 207.8L588.3 208.3C588.2 208.7 587.8 209 587.4 209ZM572 205.2L587.3 207.9V207.6L572 204.9V205.2Z"
      fill="black"
    />
    <path
      d="M586.3 215.4H586.2L570.6 212.7C570.4 212.7 570.2 212.5 570 212.3C569.8 212.1 569.8 211.9 569.9 211.7L570 211.2C570 211 570.2 210.8 570.3 210.7C570.5 210.6 570.7 210.5 570.9 210.6L586.5 213.3C586.7 213.3 586.9 213.5 587.1 213.7C587.2 213.9 587.3 214.1 587.2 214.3L587.1 214.8C587.2 215.1 586.8 215.4 586.3 215.4ZM571 211.6L586.3 214.3V214L571 211.3V211.6Z"
      fill="black"
    />
    <path
      d="M587.5 223C587.4 223 587.4 223 587.3 223L555.7 216.9C555.4 216.9 555.2 216.7 555 216.5C554.8 216.3 554.8 216 554.8 215.7L554.9 214.9C554.9 214.6 555.1 214.4 555.3 214.2C555.5 214 555.8 214 556.1 214L587.7 220.1C588 220.1 588.2 220.3 588.4 220.5C588.6 220.7 588.6 221 588.6 221.3L588.5 222.1C588.4 222.6 588 223 587.5 223ZM587.5 222L587.7 221.2L556.1 215.1L555.9 215.9L587.5 222Z"
      fill="black"
    />
    <path
      d="M568.5 213.4L555.1 211L558 194.2L571.4 196.6L568.5 213.4ZM556.2 210.2L567.5 212.2L570.1 197.4L558.8 195.4L556.2 210.2Z"
      fill="black"
    />
    <path
      d="M566.9 213.1L556.5 211.3L556.6 210.8C557.1 208 559.8 206.1 562.7 206.6C565.5 207.1 567.5 209.9 567 212.7L566.9 213.1ZM557.8 210.5L566.1 212C566.2 209.9 564.7 208 562.5 207.7C560.4 207.1 558.4 208.5 557.8 210.5ZM563.3 206.4C563.1 206.4 562.9 206.4 562.8 206.4C562 206.3 561.2 205.8 560.8 205.1C560.4 204.5 560.2 203.6 560.3 202.8C560.4 202 560.9 201.3 561.6 200.8C562.3 200.3 563.1 200.2 563.9 200.3C564.7 200.4 565.4 200.9 565.9 201.6C566.4 202.3 566.5 203.1 566.4 203.9C566.3 204.6 565.9 205.4 565.2 205.9C564.6 206.1 564 206.4 563.3 206.4ZM563.3 201.1C562.9 201.1 562.5 201.2 562.1 201.5C561.7 201.8 561.4 202.3 561.3 202.9C561.2 203.5 561.3 204 561.6 204.5C561.9 204.9 562.4 205.2 563 205.3C563.5 205.4 564.2 205.3 564.7 204.9C565.1 204.6 565.4 204.2 565.4 203.6C565.5 203.1 565.4 202.4 565.1 202C564.8 201.6 564.3 201.3 563.7 201.2C563.5 201.1 563.4 201.1 563.3 201.1Z"
      fill="black"
    />
    <path
      d="M590.3 230.801L549.7 223.401L556.3 188.101L596.9 195.501L590.3 230.801ZM550.9 222.601L589.6 229.701L595.9 196.301L557.2 189.201L550.9 222.601Z"
      fill="black"
    />
    <path
      d="M567 168L569.8 168.5C571.2 168.8 571.9 169.7 571.8 170.9C571.5 172.3 570.4 172.7 569.2 172.6L568.3 172.5L567.9 174.7L566.2 174.5L567 168ZM568.2 171.1L568.9 171.2C569.4 171.3 569.8 171.1 569.9 170.6C570 170.1 569.7 169.9 569.1 169.8L568.4 169.7L568.2 171.1Z"
      fill="#131820"
    />
    <path
      d="M573.2 174.3L572.6 175.5L570.9 175.3L574 169.5L576.2 169.9L577 176.5L575.1 176.1L575 174.8L573.2 174.3ZM574.9 173.2C574.8 172 574.8 171.3 574.7 170.6C574.4 171.2 574.1 172.2 573.5 172.9L574.9 173.2Z"
      fill="#131820"
    />
    <path
      d="M579.4 174.8C579.4 175.3 579.8 175.7 580.3 175.9C580.8 176 581.2 175.8 581.2 175.5C581.4 175.1 581 175 580 174.4C578.3 173.6 578 172.8 578.2 172.1C578.3 171.2 579.4 170.3 581 170.6C582.7 170.8 583.3 172 583.2 173L581.5 172.8C581.5 172.4 581.5 172.1 580.7 172C580.3 171.8 580 172 580 172.4C580 172.8 580.2 172.9 580.9 173.2C582.6 174 583 174.8 582.7 175.7C582.4 176.9 581.5 177.5 579.7 177.2C578 177 577.2 175.8 577.3 174.8H579.4Z"
      fill="#131820"
    />
    <path
      d="M585.4 175.9C585.4 176.4 585.8 176.8 586.3 177C586.8 177.1 587.2 176.9 587.2 176.6C587.4 176.2 587 176.1 586 175.5C584.3 174.7 584 173.9 584.2 173.2C584.3 172.3 585.4 171.4 587 171.7C588.7 171.9 589.3 173.1 589.2 174.1L587.5 173.9C587.5 173.5 587.5 173.2 586.7 173.1C586.3 172.9 586 173.1 586 173.5C586 173.9 586.2 174 586.9 174.3C588.6 175.1 589 175.9 588.7 176.8C588.4 178 587.5 178.6 585.7 178.3C584 178.1 583.2 176.9 583.3 175.9H585.4Z"
      fill="#131820"
    />
    <path
      d="M565 176.3L567.8 176.8C569.2 177.1 569.9 178 569.8 179.2C569.5 180.6 568.4 181 567.2 180.9L566.3 180.8L565.9 183L564.2 182.8L565 176.3ZM566.4 179.4L567.1 179.5C567.6 179.6 568 179.4 568.1 178.9C568.2 178.4 567.9 178.2 567.3 178.1L566.6 178L566.4 179.4Z"
      fill="#131820"
    />
    <path
      d="M576.6 181.7C576.4 183.4 574.9 184.8 572.9 184.3C570.8 183.9 570.2 182.3 570.4 180.5C570.6 178.8 572.1 177.4 574.1 177.9C576.1 178.2 576.9 179.9 576.6 181.7ZM572.1 180.7C571.8 181.9 572.1 182.7 572.9 182.9C573.8 183 574.4 182.3 574.5 181.3C574.8 180.1 574.5 179.3 573.7 179.1C573 178.9 572.4 179.5 572.1 180.7Z"
      fill="#131820"
    />
    <path
      d="M579.1 182.8L578.7 185L577 184.8L578 178.6L581 179.1C582.5 179.4 582.9 180.3 582.8 181.2C582.7 182.1 582.2 182.4 581.6 182.6C582 182.8 582.4 183.3 582.1 184.4V184.6C581.9 185 582 185.5 582 185.8L580.3 185.6C580.3 185.2 580.3 184.9 580.4 184.3V184.1C580.5 183.6 580.3 183.2 579.6 183L579.1 182.8ZM579.4 181.7L580.1 181.8C580.6 181.9 581 181.7 581 181.4C581.2 181 581 180.7 580.4 180.6L579.7 180.5L579.4 181.7Z"
      fill="#131820"
    />
    <path
      d="M585.3 181.4L583.6 181.2L583.9 179.8L589.1 180.7L588.8 182.1L587.1 181.9L586.2 186.9L584.3 186.7L585.3 181.4Z"
      fill="#131820"
    />
    <path
      d="M590.5 231.401C590.3 231.701 590.1 232.001 589.8 232.001C588.4 231.901 587.8 225.701 587.5 223.301C587.1 219.401 587.2 217.901 586.5 214.301C585.8 211.001 585 209.901 584 209.401C583.7 209.301 582.5 208.901 580.8 209.801C580.6 209.701 579.3 208.901 577.8 209.301C576.8 209.601 576.1 210.201 575.9 210.601C575.4 210.401 574.5 210.301 573.9 210.601C571.5 211.701 571.7 217.201 571.9 221.001C572.2 226.501 573.3 230.601 573.6 231.801C576.1 241.501 579.7 249.501 579.7 249.501C580.4 251.201 581.6 253.501 583 256.301C587.9 253.101 592.7 250.101 597.6 246.901C597.8 246.201 598.1 245.401 598.3 244.701C598.6 243.101 598.9 241.201 599 239.101C599.1 237.301 599.1 235.701 599 234.201L590.5 231.401Z"
      fill="white"
    />
    <path
      d="M576.7 226.601C576.4 226.601 576.1 226.401 576 226.001C575.2 222.501 574.6 219.001 574.5 215.401C574.4 213.701 574.5 212.001 574.6 210.301C574.6 209.901 575 209.601 575.4 209.601C575.8 209.601 576.1 210.001 576.1 210.401C576 212.001 576 213.701 576 215.401C576.1 218.901 576.6 222.301 577.5 225.601C577.6 226.001 577.4 226.401 576.9 226.501C576.8 226.601 576.7 226.601 576.7 226.601Z"
      fill="black"
    />
    <path
      d="M582 226.9C581.7 226.9 581.4 226.7 581.3 226.4C580.7 224.3 580.1 222.2 579.8 220.1C579.7 219.5 579.5 217.7 579.4 215.2C579.3 213.5 579.4 211.8 579.5 210.1C579.5 209.7 579.9 209.4 580.3 209.4C580.7 209.4 581 209.8 581 210.2C580.9 211.8 580.9 213.5 580.9 215.1C581 217.4 581.2 219.1 581.3 219.7C581.6 221.8 582 223.8 582.7 225.8C582.8 226.2 582.6 226.6 582.2 226.7C582.1 226.9 582 226.9 582 226.9Z"
      fill="black"
    />
    <path
      d="M582.7 257.601L582.3 256.901C580.8 254.201 579.7 251.801 579 250.001C579 249.901 575.3 241.801 572.9 232.201C572.5 230.701 571.5 226.601 571.2 221.301C570.9 216.601 570.9 211.301 573.7 210.101C574.3 209.801 575.2 209.801 575.7 209.901C576.1 209.501 576.7 209.101 577.6 208.701C578.6 208.401 579.8 208.601 580.8 209.101C582.1 208.601 583.2 208.501 584.3 208.801C585.8 209.401 586.6 210.901 587.3 214.301C587.8 216.701 587.9 218.201 588 220.101C588.1 221.001 588.1 222.001 588.3 223.301L588.4 224.101C588.9 227.901 589.3 230.201 589.8 231.001V230.901L591.4 231.101L591.3 231.601L591.2 231.801C591 232.201 590.6 232.701 589.8 232.701C588.1 232.601 587.5 229.901 586.9 224.301L586.8 223.501C586.7 222.101 586.6 221.201 586.5 220.201C586.4 218.401 586.3 216.901 585.8 214.601C585.1 211.201 584.3 210.601 583.8 210.301C583.5 210.201 582.6 209.901 581.2 210.701L580.9 210.901L580.4 210.701C580.1 210.501 579.1 210.001 578 210.301C577.2 210.501 576.6 211.001 576.5 211.201L576.2 211.701L575.6 211.501C575.2 211.401 574.7 211.301 574.2 211.501C572.2 212.301 572.5 218.601 572.6 221.201C572.9 226.401 573.9 230.401 574.3 231.801C576.8 241.301 580.3 249.301 580.4 249.401C581 250.901 582.1 253.001 583.3 255.301C585.5 253.801 587.7 252.501 589.9 251.101C592.2 249.601 594.6 248.001 597 246.601C597.1 246.301 597.2 246.101 597.3 245.801C597.4 245.501 597.5 245.101 597.6 244.701C597.8 243.401 598.2 241.501 598.3 239.301C598.4 237.501 598.4 236.001 598.3 234.601V234.501L598.4 234.101L599.9 234.501V234.601C600 236.101 600 237.701 599.9 239.501C599.8 241.801 599.4 243.801 599.2 245.201C599.1 245.601 599 246.001 598.8 246.501C598.7 246.801 598.6 247.201 598.5 247.601L598.4 247.901L598.2 248.101C595.7 249.701 593.3 251.301 590.9 252.801C588.5 254.401 586.1 255.901 583.6 257.401L582.7 257.601Z"
      fill="black"
    />
    <path
      d="M277.1 130.8L216.7 124C213.9 123.7 211.3 125.8 210.9 128.6L206.5 168.8C206.2 171.6 208.3 174.3 211.1 174.6L271.5 181.4C274.3 181.7 276.9 179.6 277.3 176.8L281.7 136.6C282 133.6 279.9 131.1 277.1 130.8Z"
      fill="white"
    />
    <path
      d="M272.1 182.4C271.9 182.4 271.7 182.4 271.4 182.4L211 175.601C209.3 175.401 207.9 174.701 206.9 173.3C205.9 172 205.4 170.3 205.6 168.8L210 128.601C210.4 125.201 213.5 122.8 216.8 123.1L277.2 129.9C278.9 130.1 280.3 130.8 281.3 132.2C282.3 133.5 282.8 135.2 282.6 136.7L278.2 176.9C278 178.6 277.3 180.1 275.9 181C274.8 181.9 273.4 182.4 272.1 182.4ZM216.1 124.9C214 124.9 212.1 126.5 211.9 128.7L207.5 168.9C207.4 170.1 207.7 171.101 208.4 172.101C209.1 172.901 210.1 173.6 211.2 173.7L271.6 180.5C272.8 180.6 273.8 180.301 274.7 179.601C275.5 178.901 276.2 177.9 276.3 176.8L280.7 136.601C280.8 135.401 280.5 134.4 279.8 133.4C279.1 132.6 278.1 131.9 277 131.8L216.6 125C216.4 124.9 216.3 124.9 216.1 124.9Z"
      fill="black"
    />
    <path d="M313.2 182.4L284 157.2L318 138.9L313.2 182.4Z" fill="#131820" />
    <path
      d="M313.2 183.3C313 183.3 312.8 183.2 312.6 183.1L283.4 157.8C283.2 157.6 283.1 157.3 283.1 157C283.1 156.7 283.3 156.4 283.6 156.3L317.6 138.1C317.9 137.9 318.3 138 318.5 138.2C318.8 138.4 318.9 138.7 318.9 139.1L314.1 182.6C314.1 182.9 313.8 183.2 313.5 183.3C313.4 183.3 313.3 183.3 313.2 183.3ZM285.7 157.4L312.5 180.6L316.9 140.7L285.7 157.4Z"
      fill="black"
    />
    <path
      d="M304.6 101.101C303 115.701 290.1 126.101 275.6 124.501C261.2 122.901 250.8 109.801 252.4 95.3007C254 80.7007 266.9 70.3007 281.4 71.9007C295.8 73.5007 306.2 86.6007 304.6 101.101Z"
      fill="white"
    />
    <path
      d="M278.6 125.7C277.6 125.7 276.6 125.6 275.6 125.5C268.4 124.7 261.9 121.1 257.4 115.4C252.9 109.7 250.8 102.5 251.6 95.1999C252.4 87.8999 256 81.3999 261.6 76.7999C267.2 72.2999 274.4 70.1999 281.6 70.9999C288.8 71.7999 295.3 75.3999 299.8 81.0999C304.3 86.7999 306.4 93.9999 305.6 101.3C304.8 108.6 301.2 115.1 295.6 119.7C290.6 123.6 284.7 125.7 278.6 125.7ZM278.5 72.5999C272.7 72.5999 267.3 74.4999 262.7 78.1999C257.5 82.3999 254.1 88.4999 253.4 95.2999C252.7 102.1 254.6 108.7 258.8 114.1C263.1 119.5 269.1 122.8 275.7 123.5C282.4 124.2 289 122.3 294.3 118C299.5 113.8 302.9 107.7 303.6 100.9C304.3 94.0999 302.4 87.4999 298.2 82.0999C294 76.7999 287.9 73.3999 281.3 72.6999C280.3 72.6999 279.4 72.5999 278.5 72.5999Z"
      fill="black"
    />
    <path
      d="M246.4 94.6007C244.8 109.201 231.9 119.601 217.4 118.001C202.9 116.401 192.6 103.301 194.2 88.8007C195.8 74.2007 208.7 63.8007 223.2 65.4007C237.7 67.0007 248 80.0007 246.4 94.6007Z"
      fill="white"
    />
    <path
      d="M220.4 119.2C219.4 119.2 218.4 119.1 217.4 119C210.2 118.2 203.7 114.6 199.2 108.9C194.7 103.2 192.5 96.0003 193.3 88.7003C195 73.6003 208.5 62.7003 223.3 64.4003C230.5 65.2003 237 68.8003 241.5 74.5003C246 80.2003 248.1 87.4003 247.3 94.7003C246.5 102 242.9 108.5 237.3 113.1C232.4 117.1 226.6 119.2 220.4 119.2ZM220.3 66.1003C207.5 66.1003 196.6 75.8003 195.2 88.8003C194.5 95.6003 196.4 102.2 200.6 107.6C204.8 113 210.9 116.3 217.5 117C224.1 117.7 230.8 115.8 236.1 111.5C241.3 107.3 244.7 101.2 245.4 94.4003C246.1 87.6003 244.2 81.0003 240 75.6003C235.8 70.2003 229.7 66.9003 223.1 66.2003C222.2 66.2003 221.2 66.1003 220.3 66.1003Z"
      fill="black"
    />
    <path d="M216.5 163.6L219.5 136L226.4 136.7L223.3 164.3L216.5 163.6Z" fill="#131820" />
    <path
      d="M791.8 545.5C805.055 545.5 815.8 534.665 815.8 521.3C815.8 507.934 805.055 497.1 791.8 497.1C778.545 497.1 767.8 507.934 767.8 521.3C767.8 534.665 778.545 545.5 791.8 545.5Z"
      fill="currentColor"
    />
    <path
      d="M791.8 546.199C778.2 546.199 767.1 534.999 767.1 521.299C767.1 507.599 778.2 496.399 791.8 496.399C805.4 496.399 816.5 507.599 816.5 521.299C816.6 535.199 805.5 546.199 791.8 546.199ZM791.8 497.999C779 497.999 768.7 508.399 768.7 521.299C768.7 534.199 779.1 544.599 791.8 544.599C804.6 544.599 814.9 534.199 814.9 521.299C815.1 508.499 804.6 497.999 791.8 497.999Z"
      fill="black"
    />
    <path
      d="M785.5 567.699C785 567.599 784.7 567.199 784.8 566.699L793.2 515.699C793.3 515.299 793.7 514.999 794.1 515.099C794.5 515.199 794.8 515.599 794.7 515.999L786.3 566.999C786.3 567.499 785.9 567.699 785.5 567.699Z"
      fill="black"
    />
    <path
      d="M822.2 544.4C825.735 544.4 828.6 541.534 828.6 538C828.6 534.465 825.735 531.6 822.2 531.6C818.665 531.6 815.8 534.465 815.8 538C815.8 541.534 818.665 544.4 822.2 544.4Z"
      fill="currentColor"
    />
    <path
      d="M822.2 545.299C818.2 545.299 815.1 541.999 815.1 538.099C815.1 534.199 818.3 530.899 822.2 530.899C826.1 530.899 829.3 534.199 829.3 538.099C829.3 541.999 826.1 545.299 822.2 545.299ZM822.2 532.399C819.2 532.399 816.7 534.899 816.7 537.999C816.7 541.099 819.2 543.599 822.2 543.599C825.2 543.599 827.7 541.099 827.7 537.999C827.7 534.899 825.2 532.399 822.2 532.399Z"
      fill="black"
    />
    <path
      d="M785.5 492.9C780.7 492.9 776.8 489 776.8 484.1C776.8 479.2 780.7 475.3 785.5 475.3C790.3 475.3 794.2 479.2 794.2 484.1C794.2 489 790.3 492.9 785.5 492.9ZM785.5 477C781.6 477 778.4 480.2 778.4 484.2C778.4 488.2 781.5 491.4 785.5 491.4C789.5 491.4 792.6 488.2 792.6 484.2C792.6 480.2 789.4 477 785.5 477Z"
      fill="black"
    />
    <path
      d="M746.5 549.4C741.7 549.4 737.8 545.5 737.8 540.6C737.8 535.7 741.7 531.8 746.5 531.8C751.3 531.8 755.2 535.7 755.2 540.6C755.2 545.5 751.3 549.4 746.5 549.4ZM746.5 533.6C742.6 533.6 739.4 536.8 739.4 540.8C739.4 544.8 742.5 548 746.5 548C750.5 548 753.6 544.8 753.6 540.8C753.6 536.7 750.5 533.6 746.5 533.6Z"
      fill="black"
    />
    <path
      d="M759.3 518.3C757.4 518.3 755.5 517.7 753.9 516.6C751.8 515.1 750.4 512.9 749.9 510.4L740.5 457.7C740.1 455.2 740.6 452.6 742.1 450.5C743.6 448.4 745.8 447 748.3 446.5C753.5 445.6 758.4 449 759.4 454.2L768.8 506.9C769.7 512.1 766.3 517.1 761.2 518.1C760.4 518.3 759.9 518.3 759.3 518.3ZM749.9 448C749.5 448 749 448 748.4 448.1C746.3 448.5 744.5 449.7 743.3 451.5C742 453.3 741.6 455.4 742 457.5L751.4 510.2C751.8 512.3 753 514.1 754.7 515.4C756.5 516.7 758.6 517.1 760.7 516.7C765 516 767.9 511.7 767.1 507.3L757.7 454.6C757 450.7 753.6 448 749.9 448Z"
      fill="black"
    />
    <path
      d="M771.4 570.2C771 570.2 770.7 570 770.7 569.6L749 457.8C748.9 457.4 749.2 457 749.6 456.9C750 456.8 750.4 457.1 750.5 457.5L772.2 569.3C772.3 569.7 772 570.1 771.6 570.3C771.5 570.2 771.5 570.2 771.4 570.2Z"
      fill="black"
    />
    <path
      d="M782.2 572.7C781.8 572.7 781.4 572.3 781.4 571.9L784.7 484.2C784.7 483.8 785 483.5 785.4 483.5C785.8 483.5 786.1 483.9 786.1 484.3L782.8 572C782.9 572.4 782.6 572.7 782.2 572.7Z"
      fill="black"
    />
    <path
      d="M806.5 568.3L812.3 551.8H809H756.8H753.5L759.3 568.3C749.8 573.7 743.6 582.2 743.6 591.8C743.6 599.4 747.5 606.4 753.7 611.5H812.1C818.4 606.2 822.2 599.4 822.2 591.8C822.2 582.2 816 573.7 806.5 568.3Z"
      fill="#D4D6DB"
    />
    <path
      d="M812 612.3H753.6C753.4 612.3 753.3 612.2 753.1 612.1C746.4 606.5 742.6 599.3 742.6 591.7C742.6 582.3 748.2 573.5 758.1 567.8L752.6 552C752.5 551.8 752.6 551.5 752.7 551.3C752.8 551.1 753.1 551 753.3 551H812.2C812.4 551 812.7 551.1 812.8 551.3C812.9 551.5 813 551.8 812.9 552L807.4 567.8C817.2 573.6 822.9 582.3 822.9 591.7C822.9 599.3 819.2 606.5 812.4 612.1C812.3 612.3 812.2 612.3 812 612.3ZM753.8 610.7H811.7C817.9 605.4 821.3 598.8 821.3 591.7C821.3 582.6 815.8 574.4 806 568.8C805.7 568.6 805.5 568.2 805.7 567.8L811 552.4H754.3L759.6 567.8C759.7 568.1 759.6 568.5 759.3 568.8C749.6 574.3 744 582.6 744 591.7C744.2 598.8 747.7 605.6 753.8 610.7Z"
      fill="black"
    />
    <path
      d="M804.6 443.901H884.7C889 443.901 892.4 440.401 892.4 436.101V368.101C892.4 363.801 888.9 360.301 884.7 360.301H804.6C800.3 360.301 796.9 363.801 796.9 368.101V436.101C796.8 440.301 800.3 443.901 804.6 443.901Z"
      fill="#D4D6DB"
    />
    <path
      d="M811.3 437H891.4C895.7 437 899.1 433.5 899.1 429.2V361.2C899.1 356.9 895.6 353.4 891.4 353.4H811.3C807 353.4 803.6 356.9 803.6 361.2V429.2C803.5 433.5 807 437 811.3 437Z"
      fill="white"
    />
    <path
      d="M891.4 437.8H811.3C806.6 437.8 802.7 433.9 802.7 429.1V361.1C802.7 356.4 806.6 352.4 811.3 352.4H891.4C896.1 352.4 900 356.3 900 361.1V429.1C900 433.9 896.1 437.8 891.4 437.8ZM811.3 354.2C807.4 354.2 804.3 357.4 804.3 361.3V429.3C804.3 433.2 807.4 436.4 811.3 436.4H891.4C895.3 436.4 898.4 433.2 898.4 429.3V361.3C898.4 357.4 895.3 354.2 891.4 354.2H811.3Z"
      fill="black"
    />
    <path
      d="M884.9 429.2H817.8C814.2 429.2 811.3 426.2 811.3 422.7V367.9C811.3 364.3 814.2 361.4 817.8 361.4H884.9C888.5 361.4 891.4 364.4 891.4 367.9V422.7C891.4 426.2 888.5 429.2 884.9 429.2ZM817.9 362.9C815.2 362.9 813 365.1 813 367.9V422.7C813 425.4 815.2 427.7 817.9 427.7H885C887.7 427.7 889.9 425.5 889.9 422.7V367.9C889.9 365.2 887.7 362.9 885 362.9H817.9Z"
      fill="black"
    />
    <path d="M849.9 421.5L835.5 400L821 421.5H849.9Z" fill="#131820" />
    <path d="M881.8 421.5L862.8 393.4L843.8 421.5H881.8Z" fill="#131820" />
    <path
      d="M834.3 383.5C834.3 386.2 836.5 388.5 839.2 388.5C841.9 388.5 844.1 386.3 844.1 383.5C844.1 380.7 841.9 378.5 839.2 378.5C836.5 378.6 834.3 380.8 834.3 383.5Z"
      fill="currentColor"
    />
    <path
      d="M839.2 389.3C836.1 389.3 833.6 386.8 833.6 383.6C833.6 380.4 836.1 377.9 839.2 377.9C842.3 377.9 844.8 380.4 844.8 383.6C844.9 386.7 842.4 389.3 839.2 389.3ZM839.2 379.4C836.9 379.4 835.1 381.3 835.1 383.5C835.1 385.7 837 387.6 839.2 387.6C841.4 387.6 843.3 385.7 843.3 383.5C843.3 381.3 841.5 379.4 839.2 379.4Z"
      fill="black"
    />
    <path
      d="M955.5 612.3H157.5C157.1 612.3 156.7 612 156.7 611.5C156.7 611 157 610.7 157.5 610.7H955.4C955.8 610.7 956.2 611 956.2 611.5C956.2 612 955.9 612.3 955.5 612.3Z"
      fill="black"
    />
    <path
      d="M312.8 442.3H234.8C228.7 442.3 223.9 447.3 223.9 453.3V456.9C223.9 464.8 223.9 472.7 223.9 480.6V481.2C223.9 487.3 228.8 492.2 234.8 492.2H312.8C318.9 492.2 323.7 487.2 323.7 481.2V453.2C323.8 447.2 318.8 442.3 312.8 442.3Z"
      fill="#D4D6DB"
    />
    <path
      d="M320.1 435.9H242.1C236 435.9 231.2 440.9 231.2 446.9V450.5C231.2 458.4 231.2 466.3 231.2 474.2V474.8C231.2 480.9 236.1 485.8 242.1 485.8H320.1C326.2 485.8 331 480.8 331 474.8V446.8C331.1 440.9 326.2 435.9 320.1 435.9Z"
      fill="white"
    />
    <path
      d="M320.1 486.8H242.1C235.6 486.8 230.4 481.5 230.4 475V447C230.4 440.5 235.6 435.2 242.1 435.2H320.1C326.6 435.2 331.8 440.5 331.8 447V475C331.8 481.4 326.6 486.8 320.1 486.8ZM242 436.7C236.5 436.7 231.9 441.3 231.9 446.9V475C231.9 480.6 236.5 485.2 242 485.2H320C325.5 485.2 330.1 480.6 330.1 475V447C330.1 441.4 325.5 436.8 320 436.8L242 436.7Z"
      fill="black"
    />
    <path
      d="M263 465.901C265.651 465.901 267.8 463.707 267.8 461.001C267.8 458.294 265.651 456.101 263 456.101C260.349 456.101 258.2 458.294 258.2 461.001C258.2 463.707 260.349 465.901 263 465.901Z"
      fill="currentColor"
    />
    <path
      d="M282.9 465.901C285.551 465.901 287.7 463.707 287.7 461.001C287.7 458.294 285.551 456.101 282.9 456.101C280.249 456.101 278.1 458.294 278.1 461.001C278.1 463.707 280.249 465.901 282.9 465.901Z"
      fill="currentColor"
    />
    <path
      d="M302.9 465.901C305.551 465.901 307.7 463.707 307.7 461.001C307.7 458.294 305.551 456.101 302.9 456.101C300.249 456.101 298.1 458.294 298.1 461.001C298.1 463.707 300.249 465.901 302.9 465.901Z"
      fill="currentColor"
    />
    <path
      d="M263 466.7C259.9 466.7 257.4 464.2 257.4 461C257.4 457.8 259.9 455.3 263 455.3C266.1 455.3 268.6 457.8 268.6 461C268.6 464.1 266.1 466.7 263 466.7ZM263 456.8C260.8 456.8 258.9 458.6 258.9 460.9C258.9 463.2 260.7 465 263 465C265.3 465 267.1 463.2 267.1 460.9C267 458.6 265.3 456.8 263 456.8Z"
      fill="black"
    />
    <path
      d="M282.9 466.7C279.8 466.7 277.3 464.2 277.3 461C277.3 457.8 279.8 455.3 282.9 455.3C286 455.3 288.5 457.8 288.5 461C288.6 464.1 286.1 466.7 282.9 466.7ZM282.9 456.8C280.7 456.8 278.8 458.6 278.8 460.9C278.8 463.2 280.6 465 282.9 465C285.2 465 287 463.2 287 460.9C287 458.6 285.3 456.8 282.9 456.8Z"
      fill="black"
    />
    <path
      d="M302.9 466.7C299.8 466.7 297.3 464.2 297.3 461C297.3 457.8 299.8 455.3 302.9 455.3C306 455.3 308.5 457.8 308.5 461C308.6 464.1 306.1 466.7 302.9 466.7ZM302.9 456.8C300.7 456.8 298.8 458.6 298.8 460.9C298.8 463.2 300.6 465 302.9 465C305.2 465 307 463.2 307 460.9C307 458.6 305.1 456.8 302.9 456.8Z"
      fill="black"
    />
    <path
      d="M231.1 475.1C230.7 475.1 230.3 474.8 230.3 474.3V450.6C230.3 450.2 230.6 449.8 231.1 449.8C231.6 449.8 231.9 450.1 231.9 450.6V474.3C231.9 474.7 231.6 475.1 231.1 475.1Z"
      fill="black"
    />
    <path
      d="M852 143.399H775.7C770.1 143.399 765.4 138.799 765.4 133.099V56.1994C765.4 50.4994 770 45.8994 775.7 45.8994H852C857.6 45.8994 862.3 50.4994 862.3 56.1994V133.099C862.2 138.799 857.6 143.399 852 143.399Z"
      fill="white"
    />
    <path
      d="M841.1 151.6H764.8C759.2 151.6 754.5 147 754.5 141.3V64.3996C754.5 58.6996 759.1 54.0996 764.8 54.0996H841.1C846.7 54.0996 851.4 58.6996 851.4 64.3996V141.3C851.4 147 846.8 151.6 841.1 151.6Z"
      fill="#D4D6DB"
    />
    <path
      d="M852 143.399H775.7C770.1 143.399 765.4 138.799 765.4 133.099V56.1994C765.4 50.4994 770 45.8994 775.7 45.8994H852C857.6 45.8994 862.3 50.4994 862.3 56.1994V133.099C862.2 138.799 857.6 143.399 852 143.399Z"
      fill="white"
    />
    <path
      d="M852 144.2H775.7C769.6 144.2 764.7 139.2 764.7 133.1V56.1996C764.7 50.0996 769.6 45.0996 775.7 45.0996H852C858.1 45.0996 863 50.0996 863 56.1996V133.1C863 139.2 858 144.2 852 144.2ZM775.7 46.6996C770.5 46.6996 766.3 50.8996 766.3 56.1996V133.1C766.3 138.4 770.5 142.6 775.7 142.6H852C857.2 142.6 861.4 138.4 861.4 133.1V56.1996C861.4 50.8996 857.2 46.6996 852 46.6996H775.7Z"
      fill="black"
    />
    <path
      d="M813.8 129.9C794.5 129.9 778.9 114.1 778.9 94.5998C778.9 75.0998 794.6 59.2998 813.8 59.2998C833 59.2998 848.7 75.0998 848.7 94.5998C848.8 114.1 833.1 129.9 813.8 129.9ZM813.8 60.9998C795.4 60.9998 780.4 76.0998 780.4 94.6998C780.4 113.3 795.4 128.4 813.8 128.4C832.2 128.4 847.2 113.3 847.2 94.6998C847.3 76.0998 832.2 60.9998 813.8 60.9998Z"
      fill="black"
    />
    <path
      d="M811.6 110.9C811.2 110.9 810.8 110.7 810.4 110.4L796.4 94.8996C795.8 94.2996 795.9 93.1996 796.5 92.6996C797.1 92.0996 798.2 92.1996 798.7 92.7996L811.5 106.8L831.5 78.8996C832 78.1996 833 78.0996 833.7 78.5996C834.4 79.0996 834.5 80.0996 834 80.7996L812.9 110.2C812.5 110.6 812.1 110.9 811.6 110.9Z"
      fill="black"
    />
    <path
      d="M267.5 364.3H193.4C188 364.3 183.5 359.8 183.5 354.3V279.6C183.5 274.1 188 269.6 193.4 269.6H267.5C272.9 269.6 277.4 274.1 277.4 279.6V354.3C277.4 359.7 272.9 364.3 267.5 364.3Z"
      fill="white"
    />
    <path
      d="M257.7 373.7H183.6C178.2 373.7 173.7 369.2 173.7 363.7V289C173.7 283.5 178.2 279 183.6 279H257.7C263.1 279 267.6 283.5 267.6 289V363.7C267.7 369.1 263.2 373.7 257.7 373.7Z"
      fill="#D4D6DB"
    />
    <path
      d="M267.5 364.3H193.4C188 364.3 183.5 359.8 183.5 354.3V279.6C183.5 274.1 188 269.6 193.4 269.6H267.5C272.9 269.6 277.4 274.1 277.4 279.6V354.3C277.4 359.7 272.9 364.3 267.5 364.3Z"
      fill="white"
    />
    <path
      d="M267.5 365.1H193.4C187.4 365.1 182.6 360.2 182.6 354.2V279.5C182.6 273.5 187.4 268.6 193.4 268.6H267.5C273.5 268.6 278.3 273.5 278.3 279.5V354.2C278.2 360.3 273.4 365.1 267.5 365.1ZM193.4 270.3C188.4 270.3 184.3 274.4 184.3 279.5V354.2C184.3 359.3 188.4 363.4 193.4 363.4H267.5C272.5 363.4 276.6 359.3 276.6 354.2V279.5C276.6 274.4 272.5 270.3 267.5 270.3H193.4Z"
      fill="black"
    />
    <path
      d="M230.4 351.2C211.7 351.2 196.4 335.8 196.4 316.9V283.4C196.4 283 196.8 282.6 197.2 282.6H263.5C263.9 282.6 264.3 283 264.3 283.4V316.9C264.4 335.8 249.2 351.2 230.4 351.2ZM198.1 284.3V316.9C198.1 334.8 212.6 349.5 230.4 349.5C248.2 349.5 262.7 334.8 262.7 316.9V284.3H198.1Z"
      fill="black"
    />
    <path d="M243.4 310.299H216.7V331.099H243.4V310.299Z" fill="#131820" />
    <path
      d="M239.6 314.999H220.7C220.3 314.999 219.9 314.599 219.9 314.199V304.599C219.9 298.899 224.5 294.199 230.3 294.199C236.1 294.199 240.7 298.799 240.7 304.599V314.199C240.5 314.699 240.1 314.999 239.6 314.999ZM221.5 313.299H238.8V304.599C238.8 299.899 234.9 295.899 230.2 295.899C225.5 295.899 221.6 299.799 221.6 304.599V313.299H221.5Z"
      fill="black"
    />
    <path
      d="M233.6 317.6C233.6 315.7 232 314.1 230.1 314.1C228.2 314.1 226.6 315.7 226.6 317.6C226.6 318.7 227.1 319.6 227.9 320.2V325.1C227.9 326.4 228.9 327.4 230.2 327.4C231.5 327.4 232.5 326.3 232.5 325.1V320.1C233.1 319.6 233.6 318.7 233.6 317.6Z"
      fill="white"
    />
    <path
      d="M457.4 149.9C456.6 149.9 455.8 149.2 455.8 148.3L455.7 140.1C455.7 139.3 456.3 138.5 457.3 138.5C458.1 138.5 458.9 139.1 458.9 140.1L459 148.3C459 149.1 458.3 149.9 457.4 149.9C457.5 149.9 457.5 149.9 457.4 149.9Z"
      fill="black"
    />
    <path
      d="M448.1 150.2C447.6 150.2 447.1 149.9 446.7 149.5L441.2 139.8C440.8 139.1 441 138.1 441.7 137.6C442.4 137.2 443.4 137.4 443.9 138.1L449.4 147.8C449.8 148.5 449.6 149.5 448.9 150C448.7 150.1 448.5 150.2 448.1 150.2Z"
      fill="black"
    />
    <path
      d="M445.2 158C445.1 158 445 158 444.9 158L436.2 156.1C435.4 155.9 434.8 155 434.9 154.2C435 153.4 435.9 152.8 436.8 152.9L445.5 154.8C446.3 155 446.9 155.9 446.8 156.7C446.6 157.5 445.9 158 445.2 158Z"
      fill="black"
    />
    <path
      d="M914.5 227.699H773C768.2 227.699 764.2 231.699 764.2 236.599V274.299C764.2 279.199 768.2 283.199 773 283.199H914.4C919.2 283.199 923.2 279.199 923.2 274.299V236.599C923.3 231.699 919.4 227.699 914.5 227.699Z"
      fill="#D4D6DB"
    />
    <path
      d="M922.1 220H780.6C775.8 220 771.8 224 771.8 228.9V266.6C771.8 271.5 775.8 275.5 780.6 275.5H922C926.8 275.5 930.8 271.5 930.8 266.6V228.9C930.9 224 927 220 922.1 220Z"
      fill="white"
    />
    <path
      d="M922.1 276.2H780.6C775.3 276.2 771 271.9 771 266.5V228.8C771 223.4 775.3 219.1 780.6 219.1H922C927.3 219.1 931.6 223.4 931.6 228.8V266.5C931.7 271.9 927.4 276.2 922.1 276.2ZM780.6 220.8C776.2 220.8 772.5 224.4 772.5 228.9V266.6C772.5 271 776.1 274.7 780.6 274.7H922C926.4 274.7 930.1 271.1 930.1 266.6V228.9C930.1 224.5 926.5 220.8 922 220.8H780.6Z"
      fill="black"
    />
    <path
      d="M903.3 264.1C894.5 264.1 887.4 256.9 887.4 248.1C887.4 239.3 894.5 232.1 903.3 232.1C912.1 232.1 919.2 239.3 919.2 248.1C919.2 256.9 912.1 264.1 903.3 264.1Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M903.3 247.8C901.1 247.8 899.4 246 899.4 243.9C899.4 241.8 901.2 240 903.3 240C905.4 240 907.2 241.8 907.2 243.9C907.3 246 905.5 247.8 903.3 247.8ZM903.3 249.7C907.2 249.7 910.4 252.9 910.4 256.8H896.3C896.3 252.9 899.5 249.7 903.3 249.7Z"
      fill="white"
    />
    <path
      d="M910.5 257.699H896.4C896 257.699 895.6 257.399 895.6 256.899C895.6 252.599 899.2 249.099 903.4 249.099C907.8 249.099 911.2 252.599 911.2 256.899C911.3 257.199 910.9 257.699 910.5 257.699ZM897.2 256.099H909.8C909.4 252.999 906.8 250.599 903.5 250.599C900.2 250.599 897.5 252.899 897.2 256.099ZM903.3 248.599C900.7 248.599 898.6 246.499 898.6 243.999C898.6 241.499 900.7 239.399 903.3 239.399C905.9 239.399 908 241.499 908 243.999C908.1 246.499 906 248.599 903.3 248.599ZM903.3 240.799C901.6 240.799 900.2 242.199 900.2 243.899C900.2 245.599 901.6 246.999 903.3 246.999C905 246.999 906.4 245.599 906.4 243.899C906.5 242.099 905.1 240.799 903.3 240.799Z"
      fill="black"
    />
    <path
      d="M871.6 259.2H791C790.6 259.2 790.2 258.9 790.2 258.4C790.2 257.9 790.5 257.6 791 257.6H871.7C872.1 257.6 872.5 257.9 872.5 258.4C872.5 258.9 872.1 259.2 871.6 259.2ZM855.7 248.8H791C790.6 248.8 790.2 248.5 790.2 248C790.2 247.5 790.5 247.2 791 247.2H855.8C856.2 247.2 856.6 247.5 856.6 248C856.6 248.5 856.2 248.8 855.7 248.8Z"
      fill="black"
    />
    <path
      d="M831.9 238.4H791.1C790.7 238.4 790.3 238.1 790.3 237.6C790.3 237.1 790.6 236.8 791.1 236.8H831.9C832.3 236.8 832.7 237.1 832.7 237.6C832.7 238.1 832.3 238.4 831.9 238.4Z"
      fill="black"
    />
  </svg>
</template>

<script>
  export default {
    name: 'SelfieImage'
  }
</script>
