<template>
  <div v-if="name">
    <div class="verifications__header-container">
      <h3 class="title">
        {{ $t('verifications.card_verification_request') }}
      </h3>
      <verification-status-chip padding-y="py-2" />
    </div>
    <v-stepper v-model="currentStageStep">
      <v-stepper-header>
        <v-card-title class="verifications__header-suptitle-container black white--text">
          <v-container fluid>
            <v-row class="flex-column-reverse flex-sm-row">
              <v-col cols="12" sm="10" class="pa-0">
                <h3 class="header">
                  {{ $t(`verifications.${activeType}.subtitle`, { name: adNetwork.name }) }}
                </h3>
              </v-col>
              <v-col cols="12" sm="2" class="pa-0 text-left text-sm-end">
                <span class="step">
                  {{
                    stageIsFinal
                      ? $t('verifications.final')
                      : $t('verifications.step', { index: currentStageStep })
                  }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content v-for="(step, index) in stepsVerification" :key="index" :step="index + 1">
          <verification-card
            v-if="!cardIsVerified"
            :ref="step.type"
            :verification-method="step"
            :errors="errors"
            @fileLoaded="onFilesLoaded($event)"
            @step-error="onStepError(index, $event)"
            @clear-error="errors[$event] = []"
          >
            <component
              :is="step.componentName"
              class="d-flex justify-center mx-auto primary--text max-height"
            />
          </verification-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card-actions class="d-flex flex-column align-end">
      <div class="d-flex flex-column justify-end flex-md-row pa-0 pt-2 pt-sm-5">
        <c-btn
          width="250"
          :disabled="stageIsFirst"
          :label="$t('main.back')"
          class="d-block d-xs-inline"
          @click="currentStageStepHandler(-1)"
        />

        <c-btn
          v-if="stageIsFinal"
          :loading="isLoading"
          class="d-block d-sm-inline ml-0 mt-2 ml-md-2 mt-md-0"
          color="primary"
          width="250"
          height="40"
          :label="$t('main.send')"
          @click.prevent="submitForm()"
        />

        <c-btn
          v-else
          class="d-block d-sm-inline ml-0 mt-2 ml-md-2 mt-md-0"
          color="primary"
          width="250"
          :label="$t('main.continue')"
          @click="currentStageStepHandler(1)"
        />
      </div>
      <errors v-if="errors.uploadError" :error-messages="errors.uploadError" />
    </v-card-actions>
  </div>
</template>

<script>
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import { mapActions, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import ValidationError from '@/services/classes/validation-error.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import verificationsRepository from '@/services/repositories/verifications-repository.js'
  import VerificationStatusChip from '@/components/VerificationStatusChip.vue'
  import SelfieImage from '@/views/Verifications/SelfieImage.vue'
  import IoImage from '@/views/Verifications/IoImage.vue'
  import PassportImage from '@/views/Verifications/PassportImage.vue'
  import CardImage from '@/views/Verifications/CardImage.vue'
  import VerificationCard from '@/views/Verifications/VerificationCard.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'Verifications',
    components: {
      Errors,
      VerificationCard,
      VerificationStatusChip,
      CBtn,
      SelfieImage,
      PassportImage,
      CardImage,
      IoImage
    },
    data() {
      return {
        currentStageStep: 1,
        isLoading: false,
        errors: {},
        stepsErrors: [],
        documents: {},
        steps: [
          {
            accept: 'image/*',
            type: 'passport_and_paper',
            verifiedCompanyShow: false,
            componentName: 'SelfieImage'
          },
          {
            accept: 'image/*',
            type: 'passport',
            verifiedCompanyShow: false,
            componentName: 'PassportImage'
          },
          {
            accept: 'image/*',
            type: 'photo_of_card',
            verifiedCompanyShow: true,
            componentName: 'CardImage'
          },
          {
            accept: 'image/*,.doc,.docx,application/msword,application/pdf',
            type: 'io',
            verifiedCompanyShow: true,
            componentName: 'IoImage'
          }
        ]
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      ...mapState('personal', ['name', 'verificationStatuses', 'isCompanyVerified']),
      stepsVerification() {
        if (this.isCompanyVerified) {
          return this.steps.filter((step) => step.verifiedCompanyShow === this.isCompanyVerified)
        }
        return this.steps
      },
      cardIsVerified() {
        return this.verificationStatuses.card === 'verified'
      },
      indexVerificationCard() {
        return this.currentStageStep - 1
      },
      stageIsFirst() {
        return this.currentStageStep === 1
      },
      activeType() {
        return this.stepsVerification[this.indexVerificationCard].type
      },
      stageIsFinal() {
        return this.currentStageStep === this.stepsVerification.length
      }
    },
    created() {
      this.fetchUserProfile()
      this.stepsErrors = this.stepsVerification.map(() => false)
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile']),
      currentStageStepHandler(carouselStep) {
        this.currentStageStep += carouselStep
      },
      onFilesLoaded({ type, documents }) {
        this.documents[type] = documents
      },
      checkFilesUpload() {
        const cards = this.stepsVerification.map((step) => this.$refs[step.type][0])
        const allFilesUploaded = cards.every((card) => card.checkFileUploadAreLoadedFiles())

        return allFilesUploaded
      },
      async submitForm() {
        const allFilesUploaded = this.checkFilesUpload()
        this.errors = {}

        if (!allFilesUploaded) {
          this.errors.uploadError = [this.$t('verifications.image_upload_error')]
          return
        }
        this.isLoading = true

        try {
          await verificationsRepository.verify({
            type: 'card',
            documents: this.stepsVerification.reduce((acc, { type }) => {
              acc[type] = this.documents[type] ?? []
              return acc
            }, {})
          })

          await this.$router.push({ name: routeNames.PERSONAL })

          gtmPush({ event: GTM_EVENTS.PAYMENT_METHOD_VERIFICATION })
          gtmPush({ event: GTM_EVENTS.CARD_VERIFICATION })
        } catch (error) {
          if (error instanceof ValidationError) {
            this.errors = error.messages
          }
        } finally {
          this.isLoading = false
        }
      },
      onStepError(index, hasError) {
        this.$set(this.stepsErrors, index, hasError)

        if (hasError) {
          this.$nextTick(() => {
            const foundErrorStepIndex = this.stepsErrors.findIndex((step) => step === true)
            this.currentStageStep = foundErrorStepIndex + 1
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .max-height {
    max-height: 300px;
  }
  ::v-deep {
    & .v-stepper__header {
      @media screen and (max-width: 1166px) {
        height: 100%;
      }
    }

    & .v-stepper__content {
      @media screen and (max-width: 1040px) {
        padding: 0;
      }
    }

    & .v-card__title {
      word-break: break-word !important;
    }
  }

  .verifications {
    &__header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 25px 0 19px 0;

      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        @media screen and (max-width: 720px) {
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 6px;
        }
      }

      @media screen and (max-width: 720px) {
        flex-direction: column;
        align-items: stretch;
        margin: 0 0 8px 0;
      }
    }

    &__header-suptitle-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      @media screen and (max-width: 720px) {
        letter-spacing: initial;
        line-height: 1.5;
      }
      .header {
        font-size: clamp(16px, 2.5vw, 20px);
      }
      .step {
        font-size: clamp(16px, 2vw, 20px);
      }
    }
  }
</style>
