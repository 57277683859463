<template>
  <div>
    <slot />
    <v-card-subtitle v-if="verificationMethod.info">
      <a :href="cardIoLink" target="_blank">
        {{ $t(`verifications.${verificationMethod.type}.info`) }}
      </a>
    </v-card-subtitle>
    <div
      v-if="verificationMethod.type === 'io' && !!adNetwork.settings.ioLink"
      class="d-flex justify-center my-2 py-2"
    >
      <c-btn
        :href="adNetwork.settings.ioLink"
        download
        color="primary"
        depressed
        tag="a"
        :label="$t(`verifications.${verificationMethod.type}.download_io`)"
      />
    </div>
    <div class="file-upload__input-wrapper">
      <file-upload
        ref="fileUpload"
        :label="`JPG, PNG, GIF. Size - 10MB maximum`"
        types="image/jpeg, image/png, image/gif"
        max-size="10MB"
        store-type="documents"
        disable-edit
        allow-multiple
        :max-files="3"
        :error-messages="errors[verificationMethod.type]"
        @update="$emit('clear-error', verificationMethod.type)"
        @verifications-input-handler="showControlsHandler($event)"
        @file-remove="onRemove"
        @process-finish="documents = $event"
      />
      <div v-if="controlsAreShown" class="file-upload__actions-wrapper">
        <div>
          <p class="description">
            {{ $t(`verifications.${verificationMethod.type}.file_upload_text`) }}
          </p>
        </div>

        <c-btn class="mr-0 mr-md-2" text outlined :label="$t('verifications.select_file')" />
      </div>
    </div>

    <errors v-if="errors.documents" :error-messages="errors.documents" class="mb-2" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import FileUpload from '@/components/FileUpload.vue'

  export default {
    name: 'VerificationCard',
    components: {
      Errors,
      FileUpload,
      CBtn
    },
    props: {
      verificationMethod: {
        type: Object,
        default: () => ({})
      },
      errors: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        documents: [],
        controlsAreShown: true
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      cardIoLink() {
        return this.$store.state.settings.cardIoLink
      },
      documentId() {
        return this.document ? this.document.id : null
      },
      type() {
        return this.verificationMethod.type
      }
    },
    watch: {
      documents() {
        this.$emit('fileLoaded', {
          type: this.verificationMethod.type,
          documents: this.documents
        })
      },
      errors(value) {
        const hasError = this.type in value && value[this.type] !== []
        this.$emit('step-error', hasError)
      }
    },
    methods: {
      showControlsHandler(event) {
        this.controlsAreShown = event === null
      },

      checkFileUploadAreLoadedFiles() {
        return this.$refs.fileUpload.checkAllFilesIsUpload()
      },
      onRemove(serverId) {
        this.documents = this.documents.filter((id) => id !== +serverId)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .file-upload {
    &__input-wrapper {
      position: relative;
    }

    &__actions-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      pointer-events: none;
      height: 140px;

      .description {
        padding: 0 12px;
        text-align: center;

        @media screen and (max-width: 720px) {
          font-size: 14px;
        }
      }
    }
  }
</style>
